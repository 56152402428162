import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'symptomChecklist',
    label: 'Symptom Checklist Report',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
