import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import IconLeft from 'cccisd-icons/arrow-left8';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import deploymentQuery from './deployment.graphql';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

const ExternalQuest = props => {
    const { handle, isPreviewMode, onComplete, onBack, defaultDate, backButtonText } = props;
    const [deploymentId, setDeploymentId] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        async function getDeployment() {
            const { id: pawnId, respondent_hash: hash } = Fortress.user.acting;

            const response = await client.query({
                query: deploymentQuery,
                fetchPolicy: 'network-only',
                variables: {
                    handle,
                    pawnId,
                },
            });
            // query returns deployments in timepoint order, so `find` will get next one
            const deployments = _get(response, 'data.flows.assignment.deploymentList', []) || [];
            const nextDeployment = deployments.find(d => d.timepoint && !d.assignmentProgress.completed);

            if (nextDeployment) {
                setDeploymentId(nextDeployment.deploymentId);
            } else {
                // trailblazer case
                const parentDeploymentId = deployments.find(d => !d.timepoint).deploymentId;
                const currentDeploymentResponse = await axios.get(
                    Boilerplate.route('api.assignmentDeployment.repeated', {
                        deploymentId: parentDeploymentId,
                        pawnId,
                        hash,
                    })
                );
                const id = _get(currentDeploymentResponse, 'data.data.deploymentId', null);
                if (id) {
                    setDeploymentId(id);
                } else {
                    setError('Could not load this survey.');
                }
            }
        }
        getDeployment();
    }, []);

    const BackButton = () => (
        <button type="button" className={'btn btn-default ' + style.backButton} onClick={onBack}>
            <IconLeft />
            &nbsp;{backButtonText || 'Back'}
        </button>
    );

    if (error) {
        return (
            <div>
                <BackButton />
                <p>{error}</p>
                <p>
                    Please contact 3C Institute for support at <a href="mailto:support@3cisd.com">support@3cisd.com</a>{' '}
                    or call <a href="tel:9195355057">(919) 535-5057</a>.
                </p>
            </div>
        );
    }

    return (
        <Loader loading={!deploymentId} removeChildren>
            <div>
                <BackButton />
            </div>
            <DeploymentPlayer
                flowProps={{
                    defaultValueVariables: {
                        [handle]: {
                            date: defaultDate,
                            startedAt: format(new Date(), 'yyyy-MM-dd'),
                        },
                    },
                }}
                deploymentId={deploymentId}
                isPreviewMode={isPreviewMode}
                onComplete={onComplete}
                pawnId={Fortress.user.acting.id}
                pawnHash={Fortress.user.acting.random_hash}
                disableLayout
            />
        </Loader>
    );
};

ExternalQuest.propTypes = {
    handle: PropTypes.string.isRequired,
    isPreviewMode: PropTypes.bool,
    onComplete: PropTypes.func,
    onBack: PropTypes.func,
    defaultDate: PropTypes.string,
    backButtonText: PropTypes.string,
};
ExternalQuest.defaultProps = {
    defaultDate: format(new Date(), 'yyyy-MM-dd'),
};

export default ExternalQuest;
