import React from 'react';
import { format, getDate, parseISO, isWithinInterval, add, nextSunday } from 'date-fns';
import PropTypes from 'prop-types';
import { ResponsiveLine } from 'cccisd-nivo/line';
import style from './style.css';

const LineChart = ({ data, xAxisDates, monthsInterval, devTag }) => {
    const chartData = [
        {
            id: devTag,
            color: style.everyBodyBlue,
            data: xAxisDates
                .map((date, i) => {
                    const datum = data.find(deployment => {
                        if (!deployment.devTags[devTag]) {
                            return false;
                        }

                        return monthsInterval === 1
                            ? deployment.completedDate === format(date, 'yyyy-MM-dd')
                            : isWithinInterval(parseISO(deployment.completedDate), {
                                  start: date,
                                  end: add(date, { weeks: 1, seconds: -1 }),
                              });
                    });

                    return datum && { x: i, y: datum.devTags[devTag] };
                })
                .filter(d => d), // remove null's
        },
    ];

    function renderXTick(tick) {
        const i = tick.value;
        if ((i !== 0 && !i) || !xAxisDates[i]) {
            return null;
        }

        const isNewMonth = i !== 0 && getDate(xAxisDates[i]) < getDate(xAxisDates[i - 1]);
        const date = format(xAxisDates[i], 'd');
        let label = (
            <text x={tick.x - (date < 10 ? 3 : 6)} y={tick.textY + 6} className={style.tick}>
                {date}
            </text>
        );

        if (i === 0 || isNewMonth) {
            label = (
                <>
                    <text
                        x={tick.x - (date < 10 ? 3 : 6)}
                        y={tick.textY + 6}
                        className={style.tick}
                    >
                        {date}
                    </text>
                    <text
                        x={tick.x - (date < 10 ? 11 : 9)}
                        y={tick.textY + 19}
                        className={`${style.tick} ${style.month}`}
                    >
                        {format(xAxisDates[i], 'MMM')}
                    </text>
                </>
            );
        }

        return label;
    }

    function renderYTick(tick) {
        return (
            <text
                x={tick.value < 10 ? tick.textX : tick.textX - 5}
                y={tick.y + 3}
                className={style.tick}
            >
                {tick.value}
            </text>
        );
    }

    function renderTooltip({ point }) {
        let displayDate = xAxisDates[point.data.x];
        if (monthsInterval === 1) {
            displayDate = add(nextSunday(displayDate), { weeks: -1 });
        }
        const dateString = format(displayDate, 'MMM do');

        let label = devTag.slice(devTag.lastIndexOf('_') + 1);
        label = label[0].toUpperCase() + label.slice(1);

        return (
            <div className={style.tooltip}>
                <p>
                    <b>Week of:</b> {dateString}
                </p>
                <p>
                    <b>{label}:</b> {point.data.y}
                </p>
            </div>
        );
    }

    const getYScaleProps = () => {
        const max = data.reduce(
            (highest, curr) =>
                curr.devTags[devTag] &&
                !Number.isNaN(curr.devTags[devTag]) &&
                parseFloat(curr.devTags[devTag]) > highest
                    ? parseFloat(curr.devTags[devTag])
                    : highest,
            0
        );

        const numOfLines = 8;
        const yTickInterval = Math.ceil(max / numOfLines);
        let tickValues = Array.from({ length: numOfLines + 1 })
            .map((_, i) => i * yTickInterval)
            .filter(val => val <= max + 2 * yTickInterval);

        if (max === 0) {
            tickValues = [0, 1, 2, 3, 4];
        }

        return {
            gridYValues: tickValues,
            yScale: {
                max: tickValues[tickValues.length - 1],
                min: 0,
                stacked: false,
                type: 'linear',
                reverse: false,
            },
            axisLeft: {
                tickValues,
                renderTick: renderYTick,
            },
        };
    };

    return (
        <div className={style.chartWrapper}>
            <div style={{ width: '100%', height: '200px' }}>
                <ResponsiveLine
                    data={chartData}
                    margin={{
                        top: 10,
                        right: 20,
                        bottom: 40,
                        left: 20,
                    }}
                    lineWidth={2}
                    enableGridY
                    enableGridX
                    legends={[]}
                    pointColor={{ from: 'color' }}
                    pointBorderColor={{ from: 'color' }}
                    colors={{ datum: 'color' }}
                    tooltip={renderTooltip}
                    axisBottom={{
                        renderTick: renderXTick,
                        tickValues: xAxisDates.map((d, i) => i),
                    }}
                    xScale={{
                        max: xAxisDates.length - 1,
                        min: 0,
                        reverse: false,
                        stacked: false,
                        type: 'linear',
                    }}
                    gridXValues={Array.from({ length: xAxisDates.length }).map((_, i) => i)}
                    {...getYScaleProps()}
                />
            </div>
        </div>
    );
};

LineChart.propTypes = {
    data: PropTypes.array.isRequired,
    xAxisDates: PropTypes.array.isRequired,
    monthsInterval: PropTypes.number.isRequired,
    devTag: PropTypes.string.isRequired,
};

export default LineChart;
