import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Html } from 'cccisd-wysiwyg';
import logo from '../../../../../../../images/everybody_logo.png';
import style from './style.css';

const dummyClientInfo = {
    firstName: 'Firstname',
    lastName: 'Client',
    coachFirstName: 'Bill',
    coachLastName: 'Bowerman',
};

const HeaderPlayer = props => {
    const { title, dateLabel, dateFormat, coachLabel, clientLabel, isFullNames } = props.settings;
    let clientInfo = props.widgetProps.clientInfo || dummyClientInfo;

    function formatName(firstName, lastName) {
        let name = '';
        if (firstName) {
            name += firstName[0].toUpperCase() + firstName.slice(1);
        }
        if (lastName) {
            name += ' ' + lastName[0].toUpperCase() + (isFullNames ? lastName.slice(1) : '.');
        }
        return name;
    }

    return (
        <header className={style.header}>
            <div className={style.logo}>
                <img src={logo} alt="logo" />
            </div>
            <div className={style.text}>
                <div className={style.title}>
                    <Html content={title} />
                </div>
                <div className={style.clientInfo}>
                    <ul>
                        <li>
                            <b>{dateLabel}:</b> {format(new Date(), dateFormat)}
                        </li>
                        {(clientInfo.coachFirstName || clientInfo.coachLastName) && (
                            <li>
                                <b>{coachLabel}:</b>{' '}
                                {formatName(clientInfo.coachFirstName, clientInfo.coachLastName)}
                            </li>
                        )}
                        <li>
                            <b>{clientLabel}:</b>{' '}
                            {formatName(clientInfo.firstName, clientInfo.lastName)}
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

HeaderPlayer.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgetProps: PropTypes.shape({
        clientInfo: PropTypes.object,
    }),
};

HeaderPlayer.defaultProps = {
    filters: {},
};

export default HeaderPlayer;
