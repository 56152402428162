import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import IconReport from 'cccisd-icons/file-text2';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import Player from '../../vendor/reports/reportTemplates/SymptomChecklist/Player';
import widgets from '../../vendor/reports/reportTemplates/widgets';
import query from './symptomChecklistProgress.graphql';
import style from './style.css';

const Appdefs = window.cccisd && window.cccisd.appDefs;

const SymptomChecklistReport = ({ trigger, clientPawnId, noModal, symptomSurveyData }) => {
    const [data, setData] = useState(symptomSurveyData || null);
    const [isOpening, setIsOpening] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const response = await client.query({
                query,
                variables: {
                    pawnId: clientPawnId,
                },
                fetchPolicy: 'network-only',
            });

            setData({
                progress: response.data.flows.assignmentProgressList,
                pawnCreated: response.data.roles.learner.pawn.createdDate,
                clientInfo: {
                    ...response.data.roles.learner.user,
                    coachFirstName:
                        response.data.roles.learner.parentRoles.instructor.user.firstName,
                    coachLastName: response.data.roles.learner.parentRoles.instructor.user.lastName,
                },
            });
            setIsOpening(false);
        }

        if ((noModal && !symptomSurveyData) || (!noModal && isOpening)) {
            fetchData();
        }
    }, [isOpening]);

    useEffect(() => {
        if (symptomSurveyData) {
            setData(symptomSurveyData);
        }
    }, [symptomSurveyData]);

    const settings =
        Appdefs &&
        Appdefs.reports &&
        Appdefs.reports.list &&
        Appdefs.reports.list.find(report => report.id === 'symptomChecklist');

    function renderModalBody() {
        if (!settings) {
            notification({
                message: `Cannot find a report where field "id" is equal to "symptomChecklist"`,
                type: 'danger',
            });
            return null;
        }

        if (!data) {
            return (
                <div className={style.inlineLoader}>
                    <Loader loading type="inline" />
                </div>
            );
        }

        return (
            <Player
                settings={settings.reportTemplateSettings}
                isPreview={false}
                filters={[]}
                widgets={widgets}
                data={data}
            />
        );
    }

    return noModal ? (
        renderModalBody()
    ) : (
        <Modal
            size="large"
            title="Symptom Checklist Report"
            trigger={trigger}
            render={renderModalBody}
            beforeShow={() => setIsOpening(true)}
            afterClose={() => setData(null)}
        />
    );
};

SymptomChecklistReport.propTypes = {
    trigger: PropTypes.node,
    clientPawnId: PropTypes.number.isRequired,
    noModal: PropTypes.bool,

    // already avaiable from Client dashboard, not from Coach dashboard
    symptomSurveyData: PropTypes.shape({
        progress: PropTypes.array,
        pawnCreated: PropTypes.string,
        clientInfo: PropTypes.object,
    }),
};

SymptomChecklistReport.defaultProps = {
    trigger: (
        <button type="button" className={classnames('btn', 'btn-default', style.reportButton)}>
            <IconReport />
            &nbsp;&nbsp;Report
        </button>
    ),
};

export default SymptomChecklistReport;
