import React from 'react';
import IconPieChart from 'cccisd-icons/pie-chart3';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

const Demographics = ({ row }) => {
    const disabled = !row['assignmentProgress.completed'];
    let clientSiteRegistrationHandle;

    if (row['fields.program'] === 'selfHelpB') {
        clientSiteRegistrationHandle = 'staywellregister';
    } else if (row['fields.program'] === 'adult') {
        clientSiteRegistrationHandle = 'adultregister';
    } else {
        clientSiteRegistrationHandle = 'teenregister';
    }

    return (
        <Modal
            title="Demographics"
            trigger={
                <Tooltip title={'Demographics' + (disabled ? ' (incomplete)' : '')}>
                    <button type="button" className={'btn btn-xs btn-primary ' + style.btnSpacing} disabled={disabled}>
                        <IconPieChart />
                    </button>
                </Tooltip>
            }
            triggerDisabled={disabled}
            size="large"
        >
            {clientSiteRegistrationHandle ? (
                <DeploymentPlayer
                    deploymentHandle={clientSiteRegistrationHandle}
                    pawnId={row['pawn.pawnId']}
                    pawnHash={row['pawn.pawnHash']}
                    disableLayout
                    flowProps={{ isPrintMode: true }}
                />
            ) : (
                <p>Could not load Demographics.</p>
            )}
        </Modal>
    );
};

export default Demographics;
