import React, { useState } from 'react';
import { client } from 'cccisd-apollo';
import IconExclamation from 'cccisd-icons/notification2';
import IconCheck from 'cccisd-icons/checkmark-circle';
import IconHourGlass from 'cccisd-icons/hour-glass2';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import Table from 'cccisd-table';
import query from './courseProgress.graphql';
import style from './style.css';

const CourseProgress = ({ row, deploymentIdMapper }) => {
    const [data, setData] = useState(null);

    let surveyProgressKey = '';
    let deploymentId = -1;
    switch (row['fields.program']) {
        case 'adult':
            surveyProgressKey = 'adultSurveyProgress';
            deploymentId = deploymentIdMapper.course;
            break;
        case 'teenUncoached':
        case 'teenCoached':
            surveyProgressKey = 'teenSurveyProgress';
            deploymentId = deploymentIdMapper.teencourse;
            break;
        case 'selfHelpB':
            surveyProgressKey = 'stayWellSurveyProgress';
            deploymentId = deploymentIdMapper.staywell;
            break;
        default:
            break;
    }

    let completed = 0;
    let total = 0;
    Object.keys(row).forEach(key => {
        if (key.startsWith(surveyProgressKey) && !key.endsWith('_lastVisit')) {
            if (row[key] === '2') {
                completed++;
            }
            total++;
        }
    });

    let icon = <IconExclamation />;
    let className = style.danger;
    if (completed === total) {
        icon = <IconCheck />;
        className = style.success;
    } else if (completed > 0) {
        icon = <IconHourGlass />;
        className = style.warning;
    }

    async function getDetailedData() {
        const resp = await client.query({
            query,
            variables: { deploymentId, pawnId: row['pawn.pawnId'] },
            fetchPolicy: 'network-only',
        });
        const lastVisitedVariables = resp?.data?.roles?.learner?.surveyProgressStatus || {};
        const allSurveys = resp?.data?.flows?.surveyList.map(surv => {
            let lastVisit = lastVisitedVariables[surv.surveyHandle + '_lastVisit'];
            const completedDate = surv?.flow?.flowProgress?.completedDate;
            if (lastVisit && lastVisit.search(/[\d]{4}-[\d]{2}-[\d]{2}/) !== -1) {
                lastVisit = lastVisit.match(/[\d]{4}-[\d]{2}-[\d]{2}/)[0];
            }

            // completedDate is timezone converted, but lastVisited is not,
            // this ensures lastVisited is never an earlier date
            // than completedDate
            if (lastVisit && completedDate && lastVisit < completedDate) {
                lastVisit = completedDate;
            }

            return {
                id: surv.surveyId,
                position: surv.position,
                label: surv.label,
                started: surv?.flow?.flowProgress?.startedDate,
                completed: completedDate,
                lastVisit,
            };
        });

        setData(allSurveys);
    }

    function renderDate(value) {
        return typeof value === 'string' && value.search(/[\d]{4}-[\d]{2}-[\d]{2}/) !== -1
            ? value.match(/[\d]{4}-[\d]{2}-[\d]{2}/)[0]
            : '-';
    }

    function renderModalContents() {
        if (!data) {
            return <Loader loading type="inline" />;
        }

        return (
            <Table
                name={'course_progress_' + row['pawn.pawnId']}
                rowKey="id"
                data={data}
                showPerPageOptions={false}
                columns={[
                    {
                        name: 'position',
                        label: '',
                        sort: true,
                        render: value => <span className={style.position}>{value}</span>,
                    },
                    {
                        name: 'label',
                        label: 'Session',
                        filter: true,
                        sort: true,
                        render: value => {
                            const regex = /theme [0-9]{1,2}/gi;
                            const hasThemeText = regex.exec(value);
                            return hasThemeText ? (
                                <span>
                                    {value.slice(0, hasThemeText.index)} <b>{hasThemeText[0]}</b>{' '}
                                    {value.slice(hasThemeText.index + hasThemeText[0].length)}
                                </span>
                            ) : (
                                value
                            );
                        },
                    },
                    {
                        name: 'started',
                        label: 'Started',
                        filter: true,
                        sort: true,
                        filterSettings: { type: 'date' },
                        render: renderDate,
                    },
                    {
                        name: 'completed',
                        label: 'Completed',
                        filter: true,
                        sort: true,
                        filterSettings: { type: 'date' },
                        render: renderDate,
                    },
                    {
                        name: 'lastVisit',
                        label: 'Last Visited',
                        filterSettings: { type: 'date' },
                        filter: true,
                        sort: true,
                        render: renderDate,
                    },
                ]}
            />
        );
    }

    return (
        <Modal
            title={'Course Progress for ' + row['user.username']}
            trigger={
                <button type="button" className={style.progressLink}>
                    <span className={className}>
                        {icon}&nbsp;
                        <span className={style.underline}>
                            {completed} session{completed !== 1 ? 's' : ''} complete
                        </span>
                    </span>
                </button>
            }
            size="large"
            beforeShow={getDetailedData}
            afterClose={() => setData(null)}
            render={modalProps => renderModalContents(modalProps)}
        />
    );
};

export default CourseProgress;
