import { set, addWeeks, nextSunday, isAfter, differenceInWeeks } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import moment from 'moment-timezone';

// i.e. 2021-04-19 20:06:46
export const getUTCStringNow = () => {
    return moment.utc().format('YYYY-MM-DD HH:mm:ss');
};

// assumes Symptom Survey re-opens at 1:00am CT Sundays
// symptomSurveyData should be in order by completedAt (descending)
export function calculateDisabled(symptomSurveyData) {
    // May 09 was a Sunday
    // const now = new Date('May 09, 2021 01:30:00 GMT-4:00'); // for testing Eastern
    // const now = new Date('May 09, 2021 00:30:00 GMT-5:00'); // for testing Central
    // const now = new Date('May 09, 2021 23:30:00 GMT-6:00'); // for testing Mountain
    // const now = new Date('May 08, 2021 23:30:00 GMT-7:00'); // for testing Pacific
    const now = new Date();

    const localOffset = now.getTimezoneOffset() / 60;

    const mostRecentProg = symptomSurveyData[0];
    if (!mostRecentProg) {
        return false;
    }

    const centralOffset = -1 * (getTimezoneOffset('America/Chicago') / 1000 / 60 / 60); // 5 or 6
    let lastSunday = set(addWeeks(nextSunday(now), -1), {
        hours: 1 + centralOffset - localOffset,
        minutes: 0,
        seconds: 0,
    });

    // Handle the "Sunday 12am-1am (Central)" case - only an issue if Central time or eastward
    if (localOffset <= centralOffset && isAfter(lastSunday, now)) {
        lastSunday = addWeeks(lastSunday, -1);
    }
    // Handle the "Saturday 11pm (Pacific)" case - only an issue if west of Central time
    else if (localOffset > centralOffset && differenceInWeeks(now, lastSunday) === 1) {
        lastSunday = addWeeks(lastSunday, 1);
    }

    return isAfter(new Date(mostRecentProg.completedAt), lastSunday);
}
