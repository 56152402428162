import React, { useState, useEffect } from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import IconUnlink from 'cccisd-icons/unlink';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import query from './coachList.graphql';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const Unlink = ({ row, reloadTables }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [coachList, setCoachList] = useState([]);
    const [selectedCoach, setSelectedCoach] = useState(row['parentRoles.instructor.pawn.pawnId'] + '');
    const [error, setError] = useState('');

    useEffect(() => {
        async function getCoaches() {
            setIsLoading(true);
            const response = await client.query({
                query,
                fetchPolicy: 'network-only',
            });

            setCoachList(
                response.data.roles.instructorList.map(instructor => ({
                    coachPawnId: instructor.pawn.pawnId,
                    username: instructor.user.username,
                    fullName: instructor.user.fullName,
                }))
            );
            setIsLoading(false);
        }

        if (isModalOpen) {
            getCoaches();
        }
    }, [isModalOpen]);

    useEffect(() => {
        setSelectedCoach(row['parentRoles.instructor.pawn.pawnId'] + '');
    }, [row]);

    async function handleSubmit(closeModal) {
        const prevCoachPawnId = row['parentRoles.instructor.pawn.pawnId'] + '';
        if (selectedCoach === prevCoachPawnId || (!selectedCoach && !prevCoachPawnId)) {
            setError(`${row['user.username']} is already assigned to this coach`);
            return;
        }

        setIsSubmitting(true);
        const response = await axios.put(Boilerplate.route('api.app.client.relink'), {
            clientPawnId: row['pawn.pawnId'],
            clientPawnHash: row['pawn.pawnHash'],
            coachPawnId: selectedCoach,
        });

        if (response.data.status === 'error') {
            notification({
                message: 'Errors changing coach:',
                type: 'danger',
                data: response.data.errors,
                duration: 0,
            });
        } else {
            notification({
                message: `Coach changed successfully for ${row['user.username']}`,
                type: 'success',
            });
        }

        setIsSubmitting(false);
        reloadTables();
        closeModal();
    }

    function handleChange(target) {
        setError('');
        setSelectedCoach(target.id);
    }

    function renderForm({ closeModal }) {
        if (isLoading) {
            return (
                <div className="text-center">
                    <Loader loading type="inline" />
                </div>
            );
        }

        const data = [
            { id: '', text: '-- Reassign automatically via algorithm --' },
            ...coachList.map(coach => ({
                id: coach.coachPawnId + '',
                text: `${coach.username} (${coach.fullName})`,
            })),
        ];

        return (
            <form name="coach-list">
                <div className={'form-group ' + (error ? 'has-error' : '')}>
                    <label htmlFor="select-coach">New Coach</label>
                    <DropdownList
                        value={data.find(d => d.id === selectedCoach)}
                        data={data}
                        dataKey="id"
                        textField="text"
                        onChange={handleChange}
                    />
                    {error && <p className="text-danger">{error}</p>}
                </div>
                {row['fields.program'] === 'teenUncoached' && (
                    <p>
                        Note: This will move this user from the <b>Teen Uncoached</b> program to the <b>Teen Coached</b>{' '}
                        program.
                    </p>
                )}
                <Button
                    title="Submit"
                    className="btn btn-primary"
                    isLoading={isSubmitting}
                    onClick={() => handleSubmit(closeModal)}
                />
            </form>
        );
    }

    return (
        <Modal
            title={`Change Coach for ${row['user.username']}`}
            trigger={
                <Tooltip title="Change Coach">
                    <button type="button" className={'btn btn-xs btn-success ' + style.btnSpacing}>
                        <IconUnlink />
                    </button>
                </Tooltip>
            }
            render={modalProps => renderForm(modalProps)}
            beforeShow={() => setIsModalOpen(true)}
            beforeClose={() => {
                setError('');
                setIsModalOpen(false);
            }}
        />
    );
};

export default Unlink;
