import React from 'react';
import MessageUser from 'cccisd-icons/envelop2';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import { SecureMessagingSingleConversation } from 'cccisd-laravel-secure-messaging';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const user = Fortress.user;

const MessageClient = props => (
    <Modal
        trigger={
            <Tooltip title="Message Client">
                <button type="button" className={'btn btn-xs btn-primary ' + style.btnSpacing}>
                    <MessageUser />
                </button>
            </Tooltip>
        }
        title="Message Client"
        render={({ row }) => (
            <div className={style.messageScroll}>
                <SecureMessagingSingleConversation
                    primaryParticipant={{
                        id: user.pawnId,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        username: user.username,
                    }}
                    anotherParticipant={{
                        id: props.row['pawn.pawnId'],
                        firstName: props.row['user.firstName'],
                        lastName: props.row['user.lastName'],
                        username: props.row['user.username'],
                    }}
                />
            </div>
        )}
    />
);

export default MessageClient;

// graphql message user and messages
