import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

const Register = props => {
    const showSuccessModal = () => {
        const modalButton = document.getElementById('modalButton');
        if (modalButton) {
            modalButton.click();
        }
    };

    const loginAfterRegister = async values => {
        const loginResponse = await axios.post(Boilerplate.route('api.nexus.login'), {
            username: values.username,
            password: values.password,
        });

        if (loginResponse.status !== 200) {
            notification({
                message: 'Registration was successful, but you could not login at this time.',
                type: 'danger',
                duration: 5000,
            });
            return;
        }

        if (loginResponse.data.notifications.length > 0) {
            loginResponse.data.notifications.forEach(note => notification(note));
        }

        if (loginResponse.data.status === 'success') {
            let afterLoginUrl = Fortress.settings.config.after_login_url;
            if (loginResponse.data.data.hasIncompleteSiteRegistration) {
                window.location = Boilerplate.url(`/account/registrationSurvey?redirectUrl=${afterLoginUrl}`);
            } else {
                window.location = Boilerplate.url(afterLoginUrl);
            }
        } else if (loginResponse.data.status === 'error') {
            notification({
                message: 'Registration was successful, but you could not login at this time.',
                type: 'danger',
                duration: 5000,
                data: loginResponse.data.errors,
            });
        }
    };
    const registerLoginProps = {
        registerProps: {
            showPasswordFields: true,
            title: 'Register',

            onRegisterSubmit: async values => {
                // Must copy values before manipulating or it will mutate Formik state object
                const formValues = { ...values };

                formValues.role = 'learner';
                formValues.program = props.program;
                formValues.username = formValues.username || formValues.email;

                const response = await axios.post(Boilerplate.route('api.nexus.pawn.store'), formValues);

                if (response.status !== 200) {
                    notification({
                        message: 'Could not register at this time.',
                        type: 'danger',
                        duration: 5000,
                        data: response.data && response.data,
                    });
                    return;
                }

                if (response.data.status === 'success') {
                    if (props.onRegisterSuccess) {
                        props.onRegisterSuccess(formValues, response.data.data);
                    } else if ('password' in formValues && 'username' in formValues) {
                        await loginAfterRegister(formValues);
                    } else {
                        showSuccessModal();
                    }
                } else if (response.data.status === 'error') {
                    // If the email field is being used as username,
                    // replace 'username' error with 'email' error
                    if (!values.username && response.data.errors.username) {
                        response.data.errors.email = response.data.errors.username;
                        delete response.data.errors.username;
                    }
                    // errors may come back as something like {values: "Missing required middle name."},
                    // so these will be displayed in a notification instead of styling in the form
                    const isErrorLocationInForm = !Object.keys(response.data.errors).find(
                        errLocation => !(errLocation in values)
                    );

                    // Throwing errors object will be picked up by formik, and style the incorrect input fields
                    if (isErrorLocationInForm) {
                        throw response.data.errors;
                    } else {
                        notification({
                            message: 'Failed to register',
                            duration: 10000,
                            type: 'danger',
                            data: response.data.errors,
                        });
                    }
                }

                if (response.data.notifications.length > 0) {
                    response.data.notifications.forEach(note => notification(note));
                }
            },

            validateAdditionalFields: values => {
                let errors = {};
                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                return errors;
            },
        },
        loginProps: { title: 'Login' },
    };
    return <RegisterLoginPage {...registerLoginProps} />;
};

export default Register;
