import React, { useState, useEffect, useRef } from 'react';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import IconProfile from 'cccisd-icons/profile';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import query from './coachBio.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const CoachBioForm = () => {
    const [isOpening, setIsOpening] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const textareaEl = useRef(null);
    const actingId = Fortress.user.acting.id;

    let defaultBio;
    try {
        defaultBio = Appdefs.pawn.fields.find(f => f.handle === 'bio').defaultForUi;
    } catch (e) {
        console.warn('Could not find default for field "bio"');
    }

    useEffect(() => {
        async function getCurrentBio() {
            const resp = await client.query({
                query,
                variables: {
                    pawnId: actingId,
                },
                fetchPolicy: 'network-only',
            });

            textareaEl.current.value = resp.data.roles.instructor.fields.bio || defaultBio;
            setIsOpening(false);
        }
        if (isOpening) {
            getCurrentBio();
        }
    }, [isOpening]);

    async function handleSubmit(e, closeModal) {
        // e is undefined when submitting via button click instead of Enter
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setIsSubmitting(true);
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', { pawn: actingId }),
            {
                pawnId: actingId,
                pawnHash: Fortress.user.acting.random_hash,
                bio: textareaEl.current.value,
            }
        );
        if (response.data.status === 'error') {
            notification({
                message: 'Error updating bio',
                data: response.data.errors,
                type: 'danger',
            });
        } else {
            closeModal();
        }
        setIsSubmitting(false);
    }

    function renderBioForm({ closeModal }) {
        return (
            <Loader loading={isOpening}>
                <form onSubmit={e => handleSubmit(e, closeModal)}>
                    <div className="form-group">
                        <label className="control-label" htmlFor="bio">
                            Bio
                        </label>
                        <textarea
                            ref={textareaEl}
                            name="bio"
                            className="form-control"
                            placeholder={isOpening ? '' : defaultBio}
                            rows={6}
                        />
                    </div>
                    <Button
                        title="Submit"
                        className="btn btn-primary"
                        isLoading={isSubmitting}
                        type="submit"
                        onClick={() => handleSubmit(null, closeModal)}
                    />
                </form>
            </Loader>
        );
    }

    return (
        <div className={'text-right ' + style.coachBio}>
            <Modal
                title="Update Bio"
                trigger={
                    <button className="btn btn-primary" type="button">
                        <IconProfile />
                        &nbsp;&nbsp;Update Bio
                    </button>
                }
                render={renderBioForm}
                beforeShow={() => setIsOpening(true)}
            />
        </div>
    );
};

export default CoachBioForm;
