import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Logs, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { Progress, Quest, ViewData } from 'cccisd-laravel-assignment';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { ResourceCenter } from 'cccisd-laravel-resources';
import SecureMessagingLearner from './components/SecureMessaging/SecureMessagingLearner';
import SecureMessagingSiteAdmin from './components/SecureMessaging/SecureMessagingSiteAdmin';
import SecureMessagingInstructor from './components/SecureMessaging/SecureMessagingInstructor';
import LearnerHomepage from './components/learnerHomepage';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Register from './pages/Register'; // Include it into main bundle
import Welcome from './pages/Welcome'; // Include it into main bundle
import Dashboard from './pages/Dashboard';
import ResourceNotAvailable from './pages/ResourceNotAvailable';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayoutFluidSecondaryNav })(Route);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    Dashboard,
    LearnerHomepage,
    Logs,
    Progress,
    ResourceCenter,
    ViewData,
    Welcome,
    SecureMessagingLearner,
    SecureMessagingSiteAdmin,
    SecureMessagingInstructor,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout]
                            ? layoutMatch[route.layout]
                            : MainLayoutFluidSecondaryNav
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact layout={MainLayoutSecondaryNav} />
        <AppRoute path="/welcome" component={Welcome} layout={MainLayoutSecondaryNav} />
        <AppRoute path="/oAxZ4aFY" component={Register} layout={MainLayout} componentProps={{ program: 'adult' }} />
        <AppRoute
            path="/mJ1VIPW9"
            layout={MainLayout}
            component={Register}
            componentProps={{
                program: 'teenCoached',
            }}
        />
        <AppRoute
            path="/zhr2H5kQ"
            layout={MainLayout}
            component={Register}
            componentProps={{
                program: 'teenUncoached',
            }}
        />
        <AppRoute
            path="/1NuKngGi"
            layout={MainLayout}
            component={Register}
            componentProps={{
                program: 'selfHelpB',
            }}
        />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluidSecondaryNav)}

        <AppRoute
            path="/account"
            layout={null}
            component={Nexus}
            componentProps={{
                defaultLayout: MainLayoutSecondaryNav,
                registrationSurveyLayout: QuestLayout,
            }}
        />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {['learner', 'guest'].includes(Fortress.user.acting.role.handle) && (
            <AppRoute
                path="/coachresources/:tab?/:id?"
                component={ResourceNotAvailable}
                layout={MainLayoutSecondaryNav}
            />
        )}

        {/* Not found page */}
        <AppRoute component={NotFound} layout={MainLayoutSecondaryNav} />
    </Switch>
);
