import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { eachDayOfInterval, add, parseISO, format, isAfter } from 'date-fns';
import { client } from 'cccisd-apollo';
import IconLock from 'cccisd-icons/lock4';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import Table from 'cccisd-table';
import Tooltip from 'cccisd-tooltip';
import clientQuery from './mealProgressClient.graphql';
import { ReportOnly, ReportButton } from '../MealReport';
import ExternalQuest from '../ExternalQuest';
/* Begin Button styling should match dashboard */
import style from '../withModules/Mediator/style.css';

const Fortress = window.cccisd.fortress;

const BeginButton = props => {
    let btn = (
        <button type="button" className={'btn ' + style.beginButton} {...props}>
            {props.disabled && (
                <>
                    <IconLock />
                    &nbsp;
                </>
            )}
            Begin
        </button>
    );
    return props.disabled ? <Tooltip title="Opens tomorrow">{btn}</Tooltip> : btn;
};

const dummyMealData = [
    {
        date: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd'),
        isPlanningComplete: false,
        planningDeploymentId: -1,
        isTrackingComplete: false,
        trackingDeploymentId: -1,
    },
    {
        date: format(new Date(), 'yyyy-MM-dd'),
        isPlanningComplete: true,
        planningDeploymentId: -1,
        isTrackingComplete: false,
        trackingDeploymentId: -1,
    },
    {
        date: format(add(new Date(), { days: -1 }), 'yyyy-MM-dd'),
        isPlanningComplete: true,
        planningDeploymentId: -1,
        isTrackingComplete: true,
        trackingDeploymentId: -1,
    },
];

const MealFullTable = ({
    trigger,
    isPreviewMode,
    clientPawnId,
    clientPawnHash,
    clientUsername,
    isClient,
    onSurveyComplete,
}) => {
    if (isClient) {
        clientPawnId = Fortress.user.acting.id;
        clientPawnHash = Fortress.user.acting.random_hash;
    }

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [reportDeployment, setReportDeployment] = useState(null);
    const [takingAssignment, setTakingAssignment] = useState(null);

    async function fetchData() {
        setIsLoading(true);
        if (isPreviewMode) {
            setData(dummyMealData);
            setIsLoading(false);
            return;
        }

        const response = await client.query({
            query: clientQuery,
            variables: {
                pawnId: clientPawnId,
            },
            fetchPolicy: 'network-only',
        });

        // for clients, show row for each day since they started the program
        // for higher roles, only show rows for which there is data submitted
        let rows = [];
        if (isClient) {
            const dates = eachDayOfInterval({
                start: add(parseISO(response.data.roles.learner.user.activatedDate), { days: 1 }),
                end: add(new Date(), { days: 1 }),
            });

            rows = dates.map(date => {
                const formattedDate = format(date, 'yyyy-MM-dd');

                const allPlanningDeployments = response.data.flows.planning.filter(
                    depl => depl.devTags.meal_planning_date === formattedDate
                );
                // get most recent completion
                const planningDeployment = allPlanningDeployments.reduce(
                    (prev, current) =>
                        prev && isAfter(new Date(prev.completedAt), new Date(current.completedAt))
                            ? prev
                            : current,
                    null
                );

                const allTrackingDeployments = response.data.flows.tracking.filter(
                    depl => depl.devTags.meal_tracking_date === formattedDate
                );
                // get most recent completion
                const trackingDeployment = allTrackingDeployments.reduce(
                    (prev, current) =>
                        prev && isAfter(new Date(prev.completedAt), new Date(current.completedAt))
                            ? prev
                            : current,
                    null
                );

                return {
                    date: formattedDate,
                    isPlanningComplete: !!planningDeployment,
                    planningDeploymentId:
                        planningDeployment && planningDeployment.deployment.deploymentId,
                    isTrackingComplete: !!trackingDeployment,
                    trackingDeploymentId:
                        trackingDeployment && trackingDeployment.deployment.deploymentId,
                };
            });
        } else {
            response.data.flows.planning.forEach(planningDeployment => {
                const date = planningDeployment.devTags.meal_planning_date;
                if (date) {
                    const row = {
                        date,
                        isPlanningComplete: true,
                        planningDeploymentId: planningDeployment.deployment.deploymentId,
                        planningCompletedAt: planningDeployment.completedAt,
                        isTrackingComplete: null,
                        trackingDeploymentId: null,
                        trackingCompletedAt: null,
                    };
                    const duplicateIndex = rows.findIndex(r => r.date === date);
                    if (duplicateIndex === -1) {
                        rows.push(row);
                    } else {
                        // keep most recent
                        const duplicate = rows[duplicateIndex];
                        if (
                            isAfter(
                                new Date(planningDeployment.completedAt),
                                new Date(duplicate.planningCompletedAt)
                            )
                        ) {
                            rows.splice(duplicateIndex, 1, row);
                        }
                    }
                }
            });
            response.data.flows.tracking.forEach(trackingDeployment => {
                const date = trackingDeployment.devTags.meal_tracking_date;
                if (date) {
                    const duplicateIndex = rows.findIndex(row => row.date === date);
                    if (duplicateIndex === -1) {
                        // must not have completed meal planning this day
                        rows.push({
                            date,
                            isPlanningComplete: false,
                            planningDeploymentId: null,
                            planningCompletedAt: null,
                            isTrackingComplete: true,
                            trackingDeploymentId: trackingDeployment.deployment.deploymentId,
                            trackingCompletedAt: trackingDeployment.completedAt,
                        });
                    } else {
                        const duplicate = rows[duplicateIndex];
                        if (!duplicate.isTrackingComplete) {
                            rows.splice(duplicateIndex, 1, {
                                ...duplicate,
                                isTrackingComplete: true,
                                trackingDeploymentId: trackingDeployment.deployment.deploymentId,
                                trackingCompletedAt: trackingDeployment.completedAt,
                            });
                        } else if (
                            isAfter(
                                new Date(trackingDeployment.completedAt),
                                new Date(duplicate.trackingCompletedAt)
                            )
                        ) {
                            rows.splice(duplicateIndex, 1, {
                                ...duplicate,
                                isTrackingComplete: true,
                                trackingDeploymentId: trackingDeployment.deployment.deploymentId,
                                trackingCompletedAt: trackingDeployment.completedAt,
                            });
                        }
                    }
                }
            });
        }

        setData(rows);
        setIsLoading(false);
    }

    const columns = [
        {
            name: 'date',
            label: 'Date',
            sort: true,
            filter: true,
            filterSettings: { type: 'date' },
            render(value) {
                return format(parseISO(value), 'E, MMM do yyyy');
            },
        },
        {
            name: 'isPlanningComplete',
            label: 'Meal Planning',
            render(value, row) {
                const noValue = isClient ? (
                    <BeginButton
                        onClick={() => {
                            if (isPreviewMode) {
                                notification({
                                    type: 'info',
                                    message: 'Not available in preview mode',
                                });
                            } else {
                                setTakingAssignment({ handle: 'mealPlanning', date: row.date });
                            }
                        }}
                    />
                ) : (
                    '-'
                );
                return value ? (
                    <ReportButton
                        onClick={() => {
                            if (isPreviewMode) {
                                notification({
                                    type: 'info',
                                    message: 'Not available in preview mode',
                                });
                            } else {
                                setReportDeployment({
                                    deploymentId: row.planningDeploymentId,
                                    date: row.date,
                                    type: 'planning',
                                });
                            }
                        }}
                    />
                ) : (
                    noValue
                );
            },
            class: 'text-center',
        },
        {
            name: 'isTrackingComplete',
            label: 'Meal Tracking',
            render(value, row) {
                const noValue = isClient ? (
                    <BeginButton
                        onClick={() => {
                            if (isPreviewMode) {
                                notification({
                                    type: 'info',
                                    message: 'Not available in preview mode',
                                });
                            } else {
                                setTakingAssignment({ handle: 'mealTracking', date: row.date });
                            }
                        }}
                        disabled={isAfter(parseISO(row.date), new Date())}
                    />
                ) : (
                    '-'
                );
                return value ? (
                    <ReportButton
                        onClick={() => {
                            if (isPreviewMode) {
                                notification({
                                    type: 'info',
                                    message: 'Not available in preview mode',
                                });
                            } else {
                                setReportDeployment({
                                    deploymentId: row.trackingDeploymentId,
                                    date: row.date,
                                    type: 'tracking',
                                });
                            }
                        }}
                    />
                ) : (
                    noValue
                );
            },
            class: 'text-center',
        },
    ];

    function modalTitle() {
        let base = 'Meals';
        if (reportDeployment) {
            base = `Meal ${reportDeployment.type === 'planning' ? 'Planning' : 'Tracking'}`;
        } else if (takingAssignment) {
            base = `Meal ${takingAssignment.handle === 'mealPlanning' ? 'Planning' : 'Tracking'}`;
        }

        return clientUsername ? base + ' for ' + clientUsername : base;
    }

    return (
        <Modal
            title={modalTitle()}
            trigger={trigger}
            size={reportDeployment || takingAssignment ? 'large' : 'medium'}
            beforeShow={fetchData}
            afterClose={() => {
                setReportDeployment(null);
                setTakingAssignment(null);
            }}
            render={({ closeModal }) => {
                if (reportDeployment) {
                    return (
                        <ReportOnly
                            deploymentId={reportDeployment.deploymentId}
                            close={() => setReportDeployment(null)}
                            date={reportDeployment.date}
                            clientPawnId={clientPawnId}
                            clientPawnHash={clientPawnHash}
                        />
                    );
                }

                if (takingAssignment) {
                    return (
                        <ExternalQuest
                            handle={takingAssignment.handle}
                            defaultDate={takingAssignment.date}
                            isPreviewMode={isPreviewMode}
                            onComplete={() => {
                                fetchData();
                                onSurveyComplete();
                            }}
                            onBack={() => setTakingAssignment(null)}
                        />
                    );
                }

                return (
                    <Loader loading={isLoading} type="inline" removeChildren>
                        <Table
                            name="mealFullTable"
                            columns={columns}
                            data={data}
                            rowKey="date"
                            isAscentOrder={false}
                        />
                    </Loader>
                );
            }}
        />
    );
};

MealFullTable.propTypes = {
    trigger: PropTypes.node,
    isClient: PropTypes.bool,
    isPreviewMode: PropTypes.bool,
    clientPawnId: PropTypes.number,
    clientPawnHash: PropTypes.string,
    clientUsername: PropTypes.string,
    onSurveyComplete: PropTypes.func,
};

export default MealFullTable;
