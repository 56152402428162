import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import moedcLogo from '../../../images/moedc_logo_full.png';
import everybodyLogo from '../../../images/everybody_logo_no_border.png';
import style from './style.css';

const hostName = window.location.hostname;

const Footer = props => {
    const { className, showPolicyLinks } = props;

    /* When playing a Course, everyBody logo is positioned differently
    because we want it to have the same background color as course layout */
    const location = useLocation();
    const isPlayingCourse = ['registration', 'course', 'finalTest', 'courseEval'].some(path =>
        location.pathname.endsWith(`/${path}`)
    );

    return (
        <footer>
            <div className={className}>
                <div className={isPlayingCourse ? style.everyBodyRowClient : style.everyBodyRow}>
                    <img src={everybodyLogo} alt="everyBody" />
                </div>
            </div>
            <div className={style.rowLogo}>
                <div className={className}>
                    <img src={moedcLogo} alt="Missouri Eating Disorders Council" />
                </div>
            </div>
            <div className={style.rowLinks}>
                <div className={className}>
                    <div className={style.container}>
                        <span>
                            Website developed by{' '}
                            <a
                                href={`//www.3cisd.com?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                className={style.noWrap}
                            >
                                3C Institute
                            </a>
                            . © {new Date().getFullYear()} Missouri Eating Disorders Council. All Rights Reserved.
                        </span>
                        {showPolicyLinks && (
                            <span className={style.noWrap}>
                                <a
                                    href={`//www.3cisd.com/?r=${hostName}/#ccc-lets-talk`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Contact Us
                                </a>
                                &nbsp;|&nbsp;
                                <a
                                    href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Terms of Use
                                </a>
                                &nbsp;|&nbsp;
                                <a
                                    href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
};

export default Footer;

/* <div className={style.footer}>
                <div className={props.className}>
                    <div className={style.wrapper}>
                        <div>
                            Software developed by{' '}
                            <a
                                href={`//www.3cisd.com?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                3C Institute.
                            </a>{' '}
                            Copyright © {new Date().getFullYear()} 3C Institute, All Rights
                            Reserved.
                        </div>
                        {props.showPolicyLinks && (
                            <div className={style.links}>
                                <a
                                    href={`//www.3cisd.com/?r=${hostName}/#ccc-lets-talk`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Contact Us
                                </a>
                                <a
                                    href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Terms of Use
                                </a>
                                <a
                                    href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div> */
