import React, { useState, useEffect, useRef } from 'react';
import { client } from 'cccisd-apollo';
import CourseWrapperMediator from 'cccisd-laravel-assignment/dist/components/navigations/courseWrapper/Mediator/CourseWrapperMediator';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import { Route, withRouter } from 'cccisd-react-router';
import ExternalQuest from '../../ExternalQuest';
import query from './symptomSurveyProgress.graphql';
import { calculateDisabled } from '../../../helpers';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const initialSymptomData = {
    progress: [{ deployment: { deploymentId: -1 }, completedAt: new Date() }],
    pawnCreated: '2021-05-10',
    clientInfo: {
        firstName: '',
        lastName: '',
        coachFirstName: '',
        coachLastName: '',
        graduatedAt: null,
    },
};

const dummySymptomData = {
    progress: [],
    pawnCreated: '2021-05-10',
    clientInfo: {
        graduatedAt: null,
        firstName: 'Morgan',
        lastName: 'Huegel',
        coachFirstName: 'Bill',
        coachLastName: 'Bowerman',
    },
};

const MediatorRoot = ownProps => {
    const {
        flowList,
        isPreviewMode,
        location: { pathname },
        mediatorProps,
    } = ownProps;
    const [isLoading, setIsLoading] = useState(true);
    const [symptomSurveyData, setSymptomSurveyData] = useState(null);
    const modalRef = useRef();

    async function getSymptomSurveyData() {
        const resp = await client.query({
            query,
            variables: {
                pawnId: Fortress.user.acting.id,
            },
            fetchPolicy: 'network-only',
        });

        if (isPreviewMode) {
            setSymptomSurveyData(dummySymptomData);
        } else {
            setSymptomSurveyData({
                progress: resp.data.flows.assignmentProgressList,
                pawnCreated: resp.data.roles.learner.pawn.createdDate,
                clientInfo: {
                    ...resp.data.roles.learner.user,
                    coachFirstName: resp.data.roles.learner.parentRoles.instructor.user.firstName,
                    coachLastName: resp.data.roles.learner.parentRoles.instructor.user.lastName,
                    graduatedAt: resp.data.roles.learner.fields.graduatedAt,
                },
            });
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getSymptomSurveyData();
    }, []);

    useEffect(() => {
        if (
            symptomSurveyData &&
            modalRef.current &&
            pathname.endsWith('/course') &&
            !symptomSurveyData?.clientInfo?.graduatedAt &&
            Fortress.user.acting.role.handle === 'learner' &&
            Fortress.user.acting.role_data.program !== 'selfHelpB'
        ) {
            const isRegistrationComplete =
                mediatorProps.registration &&
                mediatorProps.registration.options &&
                mediatorProps.registration.options.every(opt => flowList.some(f => f.handle === opt && f.isComplete));

            if (
                isRegistrationComplete &&
                !calculateDisabled(
                    symptomSurveyData.progress.map(depl => ({
                        deploymentId: depl.deployment.deploymentId,
                        completedAt: depl.completedAt,
                    }))
                )
            ) {
                modalRef.current.open();
            }
        }
    }, [modalRef.current, symptomSurveyData, pathname]);

    const renderModalBody = ({ closeModal }) => (
        <ExternalQuest
            handle="symptomChecklist"
            isPreviewMode={isPreviewMode}
            onComplete={getSymptomSurveyData}
            onBack={() => closeModal()}
            backButtonText="Dashboard"
        />
    );

    return (
        <Loader loading={isLoading} removeChildren>
            <div className={style.bodyuCourseWrapper}>
                <Modal
                    title="Symptom Checklist"
                    render={renderModalBody}
                    size="large"
                    trigger={<span style={{ display: 'none' }} />}
                    ref={modalRef}
                />
                <Route
                    path={`${ownProps.match.path}/:courseWrapperTab?`}
                    render={props => (
                        <CourseWrapperMediator
                            {...ownProps}
                            {...props}
                            basePath={ownProps.match.url}
                            symptomSurveyData={{
                                data: symptomSurveyData,
                                get: getSymptomSurveyData,
                                initial: initialSymptomData,
                            }}
                        />
                    )}
                />
            </div>
        </Loader>
    );
};

export default withRouter(MediatorRoot);
