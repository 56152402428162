import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { format, parseISO } from 'date-fns';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import IconReport from 'cccisd-icons/file-text2';
import IconLeft from 'cccisd-icons/arrow-left8';
import Modal from 'cccisd-modal';
import style from './style.css';
import notification from 'cccisd-notification';

const Fortress = window.cccisd.fortress;

export const ReportOnly = ({ close, date, deploymentId, clientPawnId, clientPawnHash }) => {
    if (!clientPawnId) {
        clientPawnId = Fortress.user.acting.id;
    }
    if (!clientPawnHash) {
        clientPawnHash = Fortress.user.acting.random_hash;
    }

    return (
        <>
            <p>
                <button type="button" className="btn btn-default" onClick={() => close()}>
                    <IconLeft />
                    &nbsp;Back
                </button>
                &nbsp;&nbsp; Viewing report from {format(parseISO(date), 'E, MMM do yyyy')}.
            </p>
            <DeploymentPlayer
                deploymentId={deploymentId}
                disableLayout
                flowProps={{ isPrintMode: true }}
                pawnId={clientPawnId}
                pawnHash={clientPawnHash}
            />
        </>
    );
};

export const ReportButton = ({ onClick, isPreviewMode }) => (
    <button
        type="button"
        className={classnames('btn', 'btn-default', style.reportButton)}
        onClick={() => {
            if (isPreviewMode) {
                notification({ type: 'info', message: 'Not available in preview mode' });
            } else if (onClick) {
                onClick();
            }
        }}
    >
        <IconReport />
        &nbsp;&nbsp;Report
    </button>
);

const MealReport = props => {
    let { handle, trigger, clientUsername, isPreviewMode } = props;
    if (!trigger) {
        trigger = <ReportButton {...props} />;
    }

    return (
        <Modal
            title={`Meal ${handle === 'mealTracking' ? 'Tracking' : 'Planning'} ${
                clientUsername ? 'for ' + clientUsername : ''
            }`}
            size="large"
            trigger={trigger}
            triggerDisabled={isPreviewMode}
            render={({ closeModal }) => <ReportOnly {...props} close={closeModal} />}
        />
    );
};

MealReport.propTypes = {
    handle: PropTypes.string.isRequired,
    trigger: PropTypes.node,
    deploymentId: PropTypes.number,
    date: PropTypes.string,
    clientPawnId: PropTypes.number, // defaults to acting
    clientPawnHash: PropTypes.string, // defaults to acting
    clientUsername: PropTypes.string,
    isPreviewMode: PropTypes.bool,
};

export default MealReport;
