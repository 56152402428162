import React from 'react';
import { Field, CccisdInput, CccisdWysiwyg } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import widgets from '../../widgets';
import initialQuestion from './questions.json';
import style from './style.css';

const initialValues = {
    headers: {
        introduction:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">INTRODUCTION</h4>',
        theme1session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 1 SESSION 1: <span style="font-weight: normal;">BALANCED EATING</span></h4>',
        theme1session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 1 SESSION 2: <span style="font-weight: normal;">COMMITTING TO CHANGE AND MEAL TRACKING</span></h4>',
        theme1session3:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 1 SESSION 3: <span style="font-weight: normal;">MINDFULNESS</span></h4>',
        theme2session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 2 SESSION 1: <span style="font-weight: normal;"> REDUCING TRIGGERS </span></h4>',
        theme2session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 2 SESSION 2: <span style="font-weight: normal;"> COPING WITH TRIGGERS </span></h4>',
        theme3session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 3 SESSION 1: <span style="font-weight: normal;"> TAMING THE CRITIC </span></h4>',
        theme3session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 3 SESSION 2: <span style="font-weight: normal;"> COMMON "THINKING" ERRORS </span></h4>',
        theme3session3:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 3 SESSION 3: <span style="font-weight: normal;"> CHALLENGING THE THIN BODY IDEAL </span></h4>',
        theme4session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 4 SESSION 1: <span style="font-weight: normal;"> SHAPE CHECKING/AVOIDANCE </span></h4>',
        theme4session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 4 SESSION 2: <span style="font-weight: normal;"> EXERCISING </span></h4>',
        theme4session3:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 4 SESSION 3: <span style="font-weight: normal;"> SELF-CARE </span></h4>',
        theme5session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 5 SESSION 1: <span style="font-weight: normal;"> MEDIA LITERACY </span></h4>',
        theme5session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 5 SESSION 2: <span style="font-weight: normal;"> SOCIAL COMPARISON </span></h4>',
        theme5session3:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 5 SESSION 3: <span style="font-weight: normal;"> SOCIAL MEDIA + SOCIAL ACTIVISM </span></h4>',
        theme6session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 6 SESSION 1: <span style="font-weight: normal;"> HOW TO BE EFFECTIVE IN INTERPERSONAL RELATIONSHIPS </span></h4>',
        theme6session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 6 SESSION 2: <span style="font-weight: normal;"> COMMUNICATING WELL AND ASSERTING YOURSELF </span></h4>',
        theme6session3:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 6 SESSION 3: <span style="font-weight: normal;"> MANAGING CRITICISM AND BODY TALK FROM FAMILY, FRIENDS, AND ROMANTIC PARTNERS </span></h4>',
        theme7session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 7 SESSION 1: <span style="font-weight: normal;"> BOOSTING YOUR POSITIVE MINDSET </span></h4>',
        theme7session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 7 SESSION 2: <span style="font-weight: normal;"> CONNECTING WITH YOUR VALUES </span></h4>',
        theme8session1:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 8 SESSION 1: <span style="font-weight: normal;"> MAINTAINING PROGRESS </span></h4>',
        theme8session2:
            '<h4 style="font-weight: bold; color: #00b5de; margin-bottom: 0px;">THEME 8 SESSION 2: <span style="font-weight: normal;"> BECOMING YOUR OWN COACH </span></h4>',
    },
    subheaders: {
        introduction_1: 'Your Personal Goals',

        theme1session1_1: 'Balanced Eating',
        theme1session1_2: 'Commit to Your Plan',
        theme1session1_3: 'Let Your Coach Know',
        theme1session1_4: 'Feedback',

        theme1session2_1: 'Committing to Change and Meal Tracking',
        theme1session2_2: 'Your Pro-Con List',
        theme1session2_3: 'It’s Your Decision!',
        theme1session2_4: 'It’s Your Decision!',
        theme1session2_5: 'Let Your Coach Know',
        theme1session2_6: 'Feedback',

        theme1session3_1: 'Maintaining Your Motivation!',
        theme1session3_2: 'So Let’s Make a Commitment!',
        theme1session3_3: 'Let Your Coach Know',
        theme1session3_4: 'Feedback',

        theme2session1_1: 'What are your Triggers?',
        theme2session1_2: 'Let Your Coach Know',
        theme2session1_3: 'Feedback',

        theme2session2_1: 'Coping with Triggers',
        theme2session2_2: 'My Alternatives to Binge Eating List',
        theme2session2_3: 'Let Your Coach Know',
        theme2session2_4: 'Feedback',

        theme3session1_1: 'Taming the Critic',
        theme3session1_2: 'Now it’s your turn!',
        theme3session1_3: 'Now it’s your turn!',
        theme3session1_4: 'Let Your Coach Know',
        theme3session1_5: 'Feedback',

        theme3session2_1: 'Common “Thinking” Errors',
        theme3session2_2: 'Feedback',

        theme3session3_1: 'Challenging the Thin Body Ideal',
        theme3session3_2: 'It can Help to Make a Pledge',
        theme3session3_3: 'Let Your Coach Know',
        theme3session3_4: 'Feedback',

        theme4session1_1: 'Shape Checking/Avoidance',
        theme4session1_2: 'Let Your Coach Know',
        theme4session1_3: 'Feedback',

        theme4session2_1: 'My Exercising Habits',
        theme4session2_2: 'Ask Yourself',
        theme4session2_3: 'Let Your Coach Know',
        theme4session2_4: 'Feedback',

        theme4session3_1: 'Reflection',
        theme4session3_2: 'Self-Care Activities',
        theme4session3_3: 'Let Your Coach Know',
        theme4session3_4: 'Feedback',

        theme5session1_1: 'The Beauty Industry',
        theme5session1_2: 'Media Activity',
        theme5session1_3: 'Examples',
        theme5session1_4: 'Let Your Coach Know',
        theme5session1_5: 'Feedback',

        theme5session2_1: 'Reflection',
        theme5session2_2: 'Reflection',
        theme5session2_3: 'Let Your Coach Know',
        theme5session2_4: 'Feedback',

        theme5session3_1: 'The Picture Perfect Bias and How it Affects You',
        theme5session3_2: 'Time to Reflect',
        theme5session3_3: 'Let Your Coach Know',
        theme5session3_4: 'Feedback',

        theme6session1_1: 'A Moment of Reflection',
        theme6session1_2:
            "Now it's Your Turn: Building, Maintaining, and Nurturing Better Relationships",
        theme6session1_3: 'Let Your Coach Know',
        theme6session1_4: 'Feedback',

        theme6session2_1: 'Now it’s Your Turn!',
        theme6session2_2: 'Time to Reflect',
        theme6session2_3: 'Let Your Coach Know',
        theme6session2_4: 'Feedback',

        theme6session3_1: 'Now it’s Your Turn!',
        theme6session3_2: 'Read Their Stories',
        theme6session3_3: 'Time to Reflect',
        theme6session3_4: 'Let Your Coach Know',
        theme6session3_5: 'Feedback',

        theme7session1_1: 'Practice Daily Positivity',
        theme7session1_2: 'Now it’s Your Turn!',
        theme7session1_3: 'Let Your Coach Know',
        theme7session1_4: 'Feedback',

        theme7session2_1: 'Getting in Touch With Your Values',
        theme7session2_2: 'Do Your Behaviors Match Your Values?',
        theme7session2_3: 'Now it’s Your Turn!',
        theme7session2_4: 'Let Your Coach Know',
        theme7session2_5: 'Feedback',

        theme8session1_1: 'First, Plan for Continued Success',
        theme8session1_2: 'Second, Know Your Potential Triggers',
        theme8session1_3: 'Your Turn',
        theme8session1_4: 'Support',
        theme8session1_5: 'Let Your Coach Know',
        theme8session1_6: 'Feedback',

        theme8session2_1: 'Becoming Your Own Coach',
        theme8session2_2: 'Successful Coaching',
        theme8session2_3: 'Final Thoughts',
    },
    questions: initialQuestion,
    filterFields: [],
    otherFilterOptions: [],
    hideFilterBar: true,
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

export { initialValues };

const SymptomChecklistBuilder = () => {
    return (
        <div>
            <BuilderBlock title="Section Headers/Subheaders">
                {Object.keys(initialValues.headers).map(key => (
                    <BuilderBlock title={key} key={key}>
                        <Field name={'headers.' + key} component={CccisdWysiwyg} label="Header" />
                        {Object.keys(initialValues.subheaders)
                            .filter(k => k.startsWith(key))
                            .map((subhead, i) => (
                                <div key={subhead + i} className={style.subsection}>
                                    <Field
                                        name={`subheaders.${key}_${i + 1}`}
                                        component={CccisdInput}
                                        label={`Subheader ${i + 1}`}
                                    />
                                    <BuilderBlock title="Questions">
                                        {Array.from({ length: 10 }).map((_, j) => {
                                            return (
                                                <Field
                                                    key={'question' + j}
                                                    name={`questions.${subhead}_${j + 1}`}
                                                    component={CccisdInput}
                                                    label={`Question ${j +
                                                        1}, devTag: "${subhead}_${j + 1}"`}
                                                />
                                            );
                                        })}
                                    </BuilderBlock>
                                </div>
                            ))}
                    </BuilderBlock>
                ))}
            </BuilderBlock>
        </div>
    );
};

export default reportTemplateBuilder({ widgets })(SymptomChecklistBuilder);
