import React, { useState } from 'react';
import axios from 'cccisd-axios';
import IconSpoon from 'cccisd-icons/spoon-knife';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import MealFullTable from '../../../components/MealFullTable';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const Meals = ({ row, loadData }) => {
    const [isMealsHighlighted, setIsMealsHighlighted] = useState(row['fields.isMealsHighlighted']);
    const [isLoading, setIsLoading] = useState(false);

    async function toggleMeals() {
        if (Fortress.user.acting.role.handle !== 'instructor' || !row['fields.isMealsHighlighted']) {
            return;
        }
        setIsLoading(true);

        // axios should toaster errors automatically
        const resp = await axios.put(Boilerplate.route('api.app.client.setDataFieldFalse'), {
            clientPawnId: row['pawn.pawnId'],
            clientPawnHash: row['pawn.pawnHash'],
            fieldToChange: 'isMealsHighlighted',
        });

        if (resp?.data?.status !== 'success') {
            notification({
                message: 'Could not toggle Meal',
                data: resp?.data?.errors || null,
                duration: 0,
                type: 'danger',
            });
        }

        setIsMealsHighlighted(false);
        setIsLoading(false);
        await loadData();
    }

    const isHighlight = ['adult', 'teenCoached'].includes(row['fields.program']) && isMealsHighlighted;

    return (
        <MealFullTable
            trigger={
                <Tooltip title="Meals">
                    <button
                        type="button"
                        className={`btn btn-xs ${isHighlight ? 'btn-warning' : 'btn-primary'} ${style.btnSpacing}`}
                        onClick={toggleMeals}
                        disabled={isLoading}
                    >
                        <IconSpoon />
                    </button>
                </Tooltip>
            }
            clientPawnId={row['pawn.pawnId']}
            clientPawnHash={row['pawn.pawnHash']}
            clientUsername={row['user.username']}
        />
    );
};

export default Meals;
