import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import widgets from '../../widgets';

export const initialValues = {
    outerInstructionalText:
        'Select a time interval to see symptoms over time. You can view older dates using the arrow buttons.',
    innerInstructionalText: '',
    concernHeader:
        'How much did concerns about your weight/shape interfere with your life and/or cause distress?',
    bingeHeader: 'Number of Binges',
    purgeHeader: 'Number of Purges',
    filterFields: [],
    otherFilterOptions: [],
    hideFilterBar: false,
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = () => {
    return (
        <div>
            <BuilderBlock title="Instructional Text">
                <Field
                    name="outerInstructionalText"
                    component={CccisdInput}
                    label="Outer Instructional Text"
                />
                <Field
                    name="innerInstructionalText"
                    component={CccisdInput}
                    label="Inner Instructional Text"
                />
            </BuilderBlock>
            <BuilderBlock title="Chart Headers">
                <Field name="concernHeader" component={CccisdInput} label="Concern Graph Header" />
                <Field name="bingeHeader" component={CccisdInput} label="Binge Graph Header" />
                <Field name="purgeHeader" component={CccisdInput} label="Purge Graph Header" />
            </BuilderBlock>
        </div>
    );
};

export default reportTemplateBuilder({ widgets })(Component);
