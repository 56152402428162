import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import {
    SecureMessagingSingleConversation,
    SecureMessagingMultipleConversations,
    SecureMessagingSupervision,
} from 'cccisd-laravel-secure-messaging';
import query from './learner.graphql';
import style from './style.css';

const Fortress = window.cccisd.fortress;

function SecureMessagingLearner() {
    const [anotherParticipant, setAnotherParticipant] = useState();
    const [loading, setLoading] = useState(true);
    const graduated = Fortress.user.acting.role_data.graduatedAt;

    useEffect(() => {
        async function fetchInstructors() {
            const response = await client.query({
                query,
                variables: {
                    pawnId: Fortress.user.acting.id,
                },
                fetchPolicy: 'network-only',
            });

            const anotherParticipants = [];
            const currentInstructor = response.data.roles.learner.parentRoles.instructor;
            if (currentInstructor) {
                const { pawn, user } = currentInstructor;
                const newParticipant = {
                    id: pawn.pawnId,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    username: user.username,
                };
                anotherParticipants.push(newParticipant);
            }

            const allConversations = response.data.messages.secureConversationList;
            if (Array.isArray(allConversations)) {
                allConversations.forEach(convo => {
                    if (convo.messageCount === 0) {
                        return;
                    }

                    const particpants = convo.participantList;
                    particpants.forEach(participant => {
                        const participantId = participant?.pawn?.pawnId;
                        if (participantId === Fortress.user.acting.id) {
                            return;
                        }

                        if (currentInstructor?.pawn?.pawnId && participantId === currentInstructor?.pawn?.pawnId) {
                            return;
                        }

                        anotherParticipants.push({
                            id: participantId,
                            firstName: participant?.user?.firstName,
                            lastName: participant?.user?.lastName,
                            username: participant?.user?.username,
                            readOnly: true,
                        });
                    });
                });
            }

            setAnotherParticipant(anotherParticipants);
            setLoading(false);
        }

        fetchInstructors();
    }, []);

    if (loading) {
        return null;
    }

    const user = Fortress.user;
    const primaryParticipant = {
        id: user.pawnId,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
    };

    if (!Fortress.user.acting.role_data.coachAcceptedAt) {
        return (
            <div className="alert alert-warning">
                <p>
                    Your Body-U Coach is still being assigned. Once the Coach assignment is complete, you will be able
                    to message your Coach here.
                </p>
            </div>
        );
    }

    if (graduated) {
        return (
            <div className={style.messageBox}>
                <div className="alert alert-warning">
                    <p>
                        Since you have graduated from the course, you can no longer send messages. However, you can
                        still review previous messages for reference.
                    </p>
                </div>

                <SecureMessagingSupervision participantIds={[Fortress.user.acting.id]} />
            </div>
        );
    }

    return (
        <div className={style.messageBox}>
            {anotherParticipant.length <= 1 ? (
                <SecureMessagingSingleConversation
                    primaryParticipant={primaryParticipant}
                    anotherParticipant={anotherParticipant[0]}
                />
            ) : (
                <SecureMessagingMultipleConversations
                    primaryParticipant={primaryParticipant}
                    anotherParticipants={anotherParticipant}
                />
            )}
        </div>
    );
}

export default SecureMessagingLearner;
