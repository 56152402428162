import React, { useState } from 'react';
import axios from 'cccisd-axios';
import IconPaper from 'cccisd-icons/paste2';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import SymptomChecklistReport from '../../../components/SymptomChecklistReport';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const Checklist = ({ row, loadData }) => {
    const [isHighlighted, setIsHighlighted] = useState(row['fields.isSymptomChecklistHighlighted']);
    const [isLoading, setIsLoading] = useState(false);

    async function toggleSymptom() {
        if (Fortress.user.acting.role.handle !== 'instructor' || !row['fields.isSymptomChecklistHighlighted']) {
            return;
        }

        setIsLoading(true);

        // axios should toaster errors automatically
        const resp = await axios.put(Boilerplate.route('api.app.client.setDataFieldFalse'), {
            clientPawnId: row['pawn.pawnId'],
            clientPawnHash: row['pawn.pawnHash'],
            fieldToChange: 'isSymptomChecklistHighlighted',
        });

        if (resp?.data?.status !== 'success') {
            notification({
                message: 'Could not toggle Checklist',
                data: resp?.data?.errors || null,
                duration: 0,
                type: 'danger',
            });
        }

        setIsHighlighted(false);
        setIsLoading(false);
        await loadData();
    }

    const isHighlight = ['adult', 'teenCoached'].includes(row['fields.program']) && isHighlighted;

    return (
        <SymptomChecklistReport
            trigger={
                <Tooltip title="Symptom Checklist">
                    <button
                        type="button"
                        className={`btn btn-xs ${isHighlight ? 'btn-warning' : 'btn-primary'} ${style.btnSpacing}`}
                        onClick={toggleSymptom}
                        disabled={isLoading}
                    >
                        <IconPaper />
                    </button>
                </Tooltip>
            }
            clientPawnId={row['pawn.pawnId']}
        />
    );
};

export default Checklist;
