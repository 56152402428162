import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import { SecureMessagingSupervision } from 'cccisd-laravel-secure-messaging';
import query from './site-admin.graphql';
import style from './style.css';

function SecureMessagingSiteAdmin() {
    const [participantIds, setParticipantIds] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchInstructors() {
            const response = await client.query({
                query,
                variables: {},
                fetchPolicy: 'network-only',
            });

            const instructorList = response.data.roles.instructorList;
            const newParticipantIds = instructorList.map(instructor => instructor.pawn.pawnId);

            setParticipantIds(newParticipantIds);
            setLoading(false);
        }

        fetchInstructors();
    }, []);

    if (loading) {
        return null;
    }

    return (
        <div className={style.messageBox}>
            <SecureMessagingSupervision participantIds={participantIds} />
        </div>
    );
}

export default SecureMessagingSiteAdmin;
