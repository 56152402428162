import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { client } from 'cccisd-apollo';
import IconCheckmark from 'cccisd-icons/svg/checkmark';
import IconLock from 'cccisd-icons/svg/lock4';
import { Player, DefaultPlayerSettingsContext } from 'cccisd-laravel-flow';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import useWithModulesMediator from './useWithModulesMediator';
import { initialValues as initialSettings } from '../Settings';
import LessonCard from './components/LessonCard';
import CoachInfo from './components/CoachInfo';
import OtherSurveyInfo from './components/OtherSurveyInfo';
import initialDataQuery from './initialData.graphql';
import style from './style.css';
import _get from 'lodash/get';

const translate = {
    en: {
        moduleText: 'THEME',
        overview: 'Overview',
    },
    es: {
        moduleText: 'MÓDULO',
        overview: 'El Resumen',
    },
};

const dummyIntialData = {
    coach: {
        user: {
            firstName: 'Bill',
            lastName: 'Bowerman',
            username: 'bill@bowerman.dead',
            email: 'bill@bowerman.dead',
        },
        fields: {
            bio:
                'William Jay Bowerman (February 19, 1911 – December 24, 1999)' +
                ' was an American track and field coach and co-founder of Nike, Inc. ' +
                'Over his career, he trained 31 Olympic athletes, 51 All-Americans, ' +
                '12 American record-holders, 22 NCAA champions and 16 sub-4 minute milers.[1]',
        },
    },
};

/*
* APP SETUP:
*   1. Course Wrapper turned on for main course
*   2. Custom withModules selected for Course tab
*   3. set modules in settings JSON course.navigationSettings:
        "navigationSettings": {
            "mustTakeInOrder": false,
            "modules": [
                {
                    "language": "en",
                    "id": 1,
                    "title": "Introduction",
                    "header": null,
                    "overview": "This is the module 1 JSON overview."
                },
                ...
            ]
        }
*   4. add keys {"moduleId": <Int>, "lessonCardTitle": <string>} to each
        survey settings
*   5. add deploymentHandles "course", "teencourse", and "staywell" to
8       their respective deployments
*   6. add assignment handles to quests "course", "mealPlanning",
        "mealTracking", and "symptomChecklist"
*   7. mealPlanning, mealTracking, and symptomChecklist have repeatable
        deployments; set a deployment settings key {"isRepeated": true}
*   8. Client site registration deployment must be made with the
        demographics survey. deploymentHandle is "clientSiteRegistration"
*   9. "Allowed to edit answers" must be turned on for mealPlanning,
        mealTracking, and the site registration deployment (demographics),
        or else Print Mode doesn't work.
*   10. In the mealPlanning and mealTracking surveys, you must set the date
        element to have the appropriate "Default value from variable"
*   11. Apply the dev_tags "meal_planning_date",
        "meal_tracking_date", "symptom_checklist_binges",
        "symptom_checklist_purges", and "symptom_checklist_concerns"
        to their respective fields in meals and symptom surveys
*   12. Create 2 reports with handles "symptomChecklist" and "courseData".
        add text and headers via Reports GUI
*   13. Create resource center with handle "instructorResources",
        or process appdefs with --force-file flag
*   14. Add devTags for main course survey (See Chelsea's Word document,
        should be a few hundred of them)
*/

const Fortress = window.cccisd.fortress;

const WithModulesMediator = props => {
    const isCoach =
        Fortress.user.acting.role.handle === 'instructor' || Fortress.user.acting.role.handle === 'siteAdmin';
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [initialData, setInitialData] = useState(null);
    const [program, setProgram] = useState(null);

    useEffect(() => {
        async function getInitialData() {
            setIsInitialLoading(true);
            const response = await client.query({
                query: initialDataQuery,
                fetchPolicy: 'network-only',
            });
            if (isPreviewMode) {
                setInitialData(dummyIntialData);
            } else {
                setInitialData({ coach: response.data.roles.learner.parentRoles.instructor });
            }
            setIsInitialLoading(false);

            const programData = _get(response, 'data.roles.learner.fields.program', {});

            setProgram(programData);
        }

        if (isCoach) {
            setIsInitialLoading(false);
        } else {
            getInitialData();
        }
    }, []);

    const coachAccepted = Fortress.user.acting.role_data.coachAcceptedAt;

    const { isNetworkError, mediatorProps, symptomSurveyData } = props;
    const isPreviewMode = props.flowList.some(f => f.isPreviewMode);
    const language = Object.keys(translate).includes(props.language) ? props.language : 'en';
    const settings = {
        ...initialSettings,
        ...mediatorProps,
    };

    const { mustTakeInOrder } = settings;
    const { closeFlowPlayer, currentFlowProps, modules, isLoading } = useWithModulesMediator({
        ...props,
        mustTakeInOrder,
    });
    const currentModule = modules.find(m => m.isActive);
    const currentModuleIndex = modules.indexOf(currentModule);

    function renderPlayerView() {
        if (!currentFlowProps) {
            return null;
        }
        return (
            <>
                <div className={style.breadcrumbs}>
                    {currentModule.title} &nbsp;&rang;{' '}
                    <span className={style.lessonCardTitle}>
                        <Html content={currentFlowProps.lessonCardTitle || currentFlowProps.title} />
                    </span>
                </div>
                <span className={classnames('pull-right', style.closeButton)} onClick={closeFlowPlayer}>
                    &times;
                </span>
                <div className={style.flow}>
                    <Player
                        key={currentFlowProps.handle}
                        {...currentFlowProps}
                        isNetworkError={isNetworkError}
                        variables={{ isCoached: ['teenCoached', 'adult'].includes(program) ? 1 : 2 }}
                    />
                </div>
            </>
        );
    }

    function renderModuleView() {
        if (currentFlowProps || !currentModule) {
            return null;
        }

        return (
            <>
                {!isCoach && coachAccepted !== null ? <CoachInfo data={initialData && initialData.coach} /> : null}
                {!isCoach && <OtherSurveyInfo isPreviewMode={isPreviewMode} symptomSurveyData={symptomSurveyData} />}

                <div className={classnames(style.wrapper, style.dashboardSection)}>
                    <div className={style.sidebar}>{renderModuleNav()}</div>
                    <div className={style.content}>
                        {renderHeader()}
                        {renderLessonArea()}
                    </div>
                </div>
            </>
        );
    }

    function renderModuleNav() {
        return (
            <div className={style.moduleNav}>
                {modules.map(module => {
                    if (module.isEmpty) {
                        return null;
                    }
                    return (
                        <div
                            key={module.id}
                            className={classnames(style.item, {
                                [style.current]: module.isActive,
                                [style.notStarted]: module.showDimmed,
                                [style.beforeActive]: modules.indexOf(module) === currentModuleIndex - 1,
                            })}
                            onClick={module.lessons.length > 0 && module.isAllowed ? module.setActive : () => {}}
                        >
                            <p
                                className={classnames(
                                    style.title,
                                    style.moduleHeader,
                                    module.showDimmed ? null : style.moduleHeaderActive
                                )}
                            >
                                {getModuleHeaderText(module)}
                            </p>
                            <p className={style.moduleTitle}>{module.title}</p>
                            {module.isComplete && !module.showDimmed && (
                                <div className={classnames(style.moduleIcon, style.checkmark)}>
                                    <IconCheckmark />
                                </div>
                            )}
                            {module.showDimmed && (
                                <div className={style.moduleIcon}>
                                    <IconLock />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    function renderHeader() {
        return (
            <div className={style.header}>
                <p className={classnames(style.headerModule, style.title)}>{getModuleHeaderText(currentModule)}</p>
                <p className={style.headerTitle}>{currentModule.title}</p>
            </div>
        );
    }

    function getModuleHeaderText(module) {
        return 'header' in module ? module.header : translate[language].moduleText + ' ' + module.id;
    }

    function renderOverview() {
        if (!currentModule.overview) {
            return null;
        }
        return (
            <div className={style.overview}>
                <h5>{currentModule.overviewTitle || translate[language].overview}</h5>
                <div className={style.overviewContent}>
                    <Html content={currentModule.overview} />
                </div>
            </div>
        );
    }

    function renderLessonArea() {
        return (
            <div className={style.lessonWrapper}>
                {renderOverview()}
                <div className={style.lessonArea}>
                    {currentModule.lessons.map((item, index) => (
                        <LessonCard
                            key={index}
                            item={item}
                            language={language}
                            lessonNum={index}
                            lessons={currentModule.lessons}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <DefaultPlayerSettingsContext.Provider
            value={{
                background: 'solidColor',
                backgroundColor: '#fff',
                height: '600px',
            }}
        >
            <Loader loading={isInitialLoading || isLoading} removeChildren={isInitialLoading}>
                {renderPlayerView()}
                {renderModuleView()}
            </Loader>
        </DefaultPlayerSettingsContext.Provider>
    );
};

export default WithModulesMediator;
