import React, { useState } from 'react';
import axios from 'cccisd-axios';
import IconChart from 'cccisd-icons/stats-bars2';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import IconLeft from 'cccisd-icons/arrow-left8';
import Player from '../../../vendor/reports/reportTemplates/CourseData/Player';
import widgets from '../../../vendor/reports/reportTemplates/widgets';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const REPORT_HANDLE_MAP = {
    adult: 'courseData',
    teenCoached: 'teencourseData',
    teenUncoached: 'teencourseData',
};

const DEPLOYMENT_HANDLE_MAP = {
    adult: 'course',
    teenCoached: 'teencourse',
    teenUncoached: 'teencourse',
    stayWell: 'staywell',
};

const CourseData = ({ row, loadData }) => {
    const [isCourseDataHighlighted, setIsCourseDataHighlighted] = useState(row['fields.isCourseDataHighlighted']);
    const [isLoading, setIsLoading] = useState(false);

    const program = row['fields.program'];
    if (!program || !(program in REPORT_HANDLE_MAP)) {
        return <span />;
    }

    const settings =
        Appdefs &&
        Appdefs.reports &&
        Appdefs.reports.list &&
        Appdefs.reports.list.find(report => report.id === REPORT_HANDLE_MAP[program]);

    async function toggleCourse() {
        if (Fortress.user.acting.role.handle !== 'instructor' || !row['fields.isCourseDataHighlighted']) {
            return;
        }

        setIsLoading(true);

        // axios should toaster errors automatically
        const resp = await axios.put(Boilerplate.route('api.app.client.setDataFieldFalse'), {
            clientPawnId: row['pawn.pawnId'],
            clientPawnHash: row['pawn.pawnHash'],
            fieldToChange: 'isCourseDataHighlighted',
        });

        if (resp?.data?.status !== 'success') {
            notification({
                message: 'Could not toggle Course',
                data: resp?.data?.errors || null,
                duration: 0,
                type: 'danger',
            });
        }

        setIsCourseDataHighlighted(false);
        setIsLoading(false);
        await loadData();
    }

    function renderModalBody({ closeModal }) {
        if (!settings) {
            notification({
                message: `Cannot find a report where field "id" is equal to "courseData"`,
                type: 'danger',
            });
            return null;
        }

        return (
            <div>
                <button type="button" className={'btn btn-default ' + style.backButton} onClick={() => closeModal()}>
                    <IconLeft />
                    &nbsp;Back
                </button>
                <Player
                    settings={settings.reportTemplateSettings}
                    deploymentHandle={DEPLOYMENT_HANDLE_MAP[program]}
                    isPreview={false}
                    filters={[]}
                    widgets={widgets}
                    pawnId={row['pawn.pawnId']}
                    clientInfo={{
                        firstName: row['user.firstName'],
                        lastName: row['user.lastName'],
                        coachFirstName: row['parentRoles.instructor.user.firstName'],
                        coachLastName: row['parentRoles.instructor.user.lastName'],
                    }}
                />
            </div>
        );
    }

    const isHighlight = ['adult', 'teenCoached'].includes(program) && isCourseDataHighlighted;

    return (
        <Modal
            title={'Course Data for ' + row['user.username']}
            trigger={
                <Tooltip title="Course Data">
                    <button
                        type="button"
                        className={`btn btn-xs ${isHighlight ? 'btn-warning' : 'btn-primary'} ${style.btnSpacing}`}
                        onClick={toggleCourse}
                        disabled={isLoading}
                    >
                        <IconChart />
                    </button>
                </Tooltip>
            }
            clientPawnId={row['pawn.pawnId']}
            clientPawnHash={row['pawn.pawnHash']}
            render={renderModalBody}
            size="large"
        />
    );
};

export default CourseData;
