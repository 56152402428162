import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format, sub, isAfter, parseISO, eachDayOfInterval, eachWeekOfInterval } from 'date-fns';
import IconLeft from 'cccisd-icons/arrow-left3';
import IconRight from 'cccisd-icons/arrow-right3';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import widgets from '../../widgets';
import ConcernChart from './components/ConcernChart';
import LineChart from './components/LineChart';
import style from './style.css';

const dummyData = {
    progress: [
        {
            completedDate: '2021-05-12',
            deployment: { deploymentId: 42 },
            devTags: {
                symptom_checklist_binges: '0',
                symptom_checklist_purges: '1',
                symptom_checklist_concerns: 'Never',
            },
        },
        {
            completedDate: '2021-05-05',
            deployment: { deploymentId: 41 },
            devTags: {
                symptom_checklist_binges: '1',
                symptom_checklist_purges: '0',
                symptom_checklist_concerns: 'Rarely',
            },
        },
        {
            completedDate: '2021-04-31',
            deployment: { deploymentId: 40 },
            devTags: {
                symptom_checklist_binges: '2',
                symptom_checklist_purges: '2',
                symptom_checklist_concerns: 'Never',
            },
        },
        {
            completedDate: '2021-04-24',
            deployment: { deploymentId: 39 },
            devTags: {
                symptom_checklist_binges: '4',
                symptom_checklist_purges: '3',
                symptom_checklist_concerns: 'Rarely',
            },
        },
        {
            completedDate: '2021-04-14',
            deployment: { deploymentId: 38 },
            devTags: {
                symptom_checklist_binges: '5',
                symptom_checklist_purges: '6',
                symptom_checklist_concerns: 'Often',
            },
        },
        {
            completedDate: '2021-04-09',
            deployment: { deploymentId: 37 },
            devTags: {
                symptom_checklist_binges: '5',
                symptom_checklist_purges: '6',
                symptom_checklist_concerns: 'Often',
            },
        },
        {
            completedDate: '2021-03-22',
            deployment: { deploymentId: 36 },
            devTags: {
                symptom_checklist_binges: '16',
                symptom_checklist_purges: '22',
                symptom_checklist_concerns: 'Always',
            },
        },
    ],
    pawnCreated: '2020-09-01',
};

const SymptomChecklistPlayer = props => {
    const {
        widgets: { header },
        settings: {
            outerInstructionalText,
            innerInstructionalText,
            concernHeader,
            bingeHeader,
            purgeHeader,
        },
    } = props;

    const { progress } = props.isPreview ? dummyData : props.data;
    const [monthsInterval, setMonthsInterval] = useState(1);
    const [startDate, setStartDate] = useState(sub(new Date(), { months: 1 }));
    const [endDate, setEndDate] = useState(new Date());

    const paginateDates = direction => {
        const setDate = prevState =>
            sub(prevState, { months: direction === 'down' ? monthsInterval : -1 * monthsInterval });
        setStartDate(setDate);
        setEndDate(setDate);
    };

    const filteredProgress = progress.filter(({ completedDate }) => {
        return (
            (isAfter(parseISO(completedDate), startDate) ||
                completedDate === format(startDate, 'yyyy-MM-dd')) &&
            isAfter(endDate, parseISO(completedDate))
        );
    });

    const xAxisDates =
        monthsInterval === 1
            ? eachDayOfInterval({ start: startDate, end: endDate })
            : eachWeekOfInterval({ start: startDate, end: endDate }, { weekStartsOn: 0 });

    const commonChartProps = {
        data: filteredProgress,
        xAxisDates,
        monthsInterval,
    };

    function handleClickMonths(numOfMonths) {
        setMonthsInterval(numOfMonths);
        setEndDate(new Date());
        setStartDate(sub(new Date(), { months: numOfMonths }));
    }

    // progress ordered by completedAt descending
    let earliestProgress = progress.length && progress[progress.length - 1].completedDate;

    return (
        <div>
            <div className={style.aboveReport}>
                <div>{outerInstructionalText}</div>
                <div className={style.filterContainer}>
                    <div className={style.btnRow}>
                        <button
                            type="button"
                            className={monthsInterval === 1 ? 'btn btn-primary' : 'btn btn-default'}
                            onClick={() => handleClickMonths(1)}
                        >
                            1 Month
                        </button>
                        <button
                            type="button"
                            className={monthsInterval === 3 ? 'btn btn-primary' : 'btn btn-default'}
                            onClick={() => handleClickMonths(3)}
                        >
                            3 Months
                        </button>
                        <button
                            type="button"
                            className={monthsInterval === 6 ? 'btn btn-primary' : 'btn btn-default'}
                            onClick={() => handleClickMonths(6)}
                        >
                            6 Months
                        </button>
                    </div>
                    <div>
                        <button
                            type="button"
                            className={'btn btn-xs btn-link ' + style.dateButton}
                            onClick={() => paginateDates('down')}
                            disabled={
                                !earliestProgress || isAfter(parseISO(earliestProgress), startDate)
                            }
                        >
                            <span>
                                <IconLeft />
                            </span>
                            <span>
                                <IconLeft />
                            </span>
                        </button>
                        <span>
                            {format(startDate, 'MM/dd/yyyy')} - {format(endDate, 'MM/dd/yyyy')}
                        </span>
                        <button
                            type="button"
                            className={'btn btn-xs btn-link ' + style.dateButton}
                            onClick={() => paginateDates('up')}
                            disabled={isAfter(endDate, sub(new Date(), { days: 1 }))}
                        >
                            <span>
                                <IconRight />
                            </span>
                            <span>
                                <IconRight />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <Report
                downloadFilename={`Symptom Checklist Report_${format(
                    startDate,
                    'MMM dd yyyy'
                )} - ${format(endDate, 'MMM dd yyyy')}`}
            >
                <Page>
                    <div>
                        {header}
                        {innerInstructionalText && (
                            <p className={style.innerInstructionalText}>{innerInstructionalText}</p>
                        )}
                        {concernHeader && (
                            <h5 className={style.sectionHeader}>
                                <b>{concernHeader}</b>
                            </h5>
                        )}
                        <ConcernChart {...commonChartProps} />
                        {bingeHeader && (
                            <h5 className={style.sectionHeader}>
                                <b>{bingeHeader}</b>
                            </h5>
                        )}
                        <LineChart {...commonChartProps} devTag="symptom_checklist_binges" />
                        {purgeHeader && (
                            <h5 className={style.sectionHeader}>
                                <b>{purgeHeader}</b>
                            </h5>
                        )}
                        <LineChart {...commonChartProps} devTag="symptom_checklist_purges" />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

SymptomChecklistPlayer.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.shape({
        progress: PropTypes.array,
        pawnCreated: PropTypes.string,
    }),
};

export default reportTemplatePlayer({
    getWidgets: props =>
        widgets.map(widget => ({
            ...widget,
            widgetProps: { clientInfo: props.data && props.data.clientInfo },
        })),
    filterFields: [],
})(SymptomChecklistPlayer);
