import React from 'react';
import PropTypes from 'prop-types';
import { add, format, getDate, isWithinInterval, parseISO } from 'date-fns';
import IconStar from 'cccisd-icons/star';
import style from './style.css';

const tableWidth = 708;
const cellHeight = 35;
const metricPositionMapper = {
    never: cellHeight,
    rarely: cellHeight * 2,
    sometimes: cellHeight * 3,
    often: cellHeight * 4,
    always: cellHeight * 5,
};

const ConcernChart = ({ data, xAxisDates, monthsInterval }) => {
    const cellWidth = (tableWidth - 50) / (xAxisDates.length - 1); // 50 is the width of title column

    function renderRow(className) {
        return Array.from({ length: xAxisDates.length - 1 }, (v, i) => i).map(i => (
            <td className={className} key={i} style={{ width: cellWidth, height: cellHeight }} />
        ));
    }

    function renderAxisRow() {
        return Array.from({ length: xAxisDates.length }, (v, i) => i).map(i => renderXAxisCell(i));
    }

    function renderXAxisCell(i) {
        const date = xAxisDates[i];
        const isNewMonth = i !== 0 && getDate(date) < getDate(xAxisDates[i - 1]);
        let label = format(date, 'd');
        if (i === 0 || isNewMonth) {
            label = (
                <div>
                    <div className={style.monthLabel}>{format(date, 'd')}</div>
                    <div className={style.monthLabel}>{format(date, 'MMM')}</div>
                </div>
            );
        }

        const datum = data.find(deployment => {
            if (!deployment.devTags.symptom_checklist_concerns) {
                return false;
            }

            return monthsInterval === 1
                ? deployment.completedDate === format(date, 'yyyy-MM-dd')
                : isWithinInterval(parseISO(deployment.completedDate), {
                      start: date,
                      end: add(date, { weeks: 1, seconds: -1 }),
                  });
        });

        const dataPoint = datum && (
            <div
                className={style.dataPoint}
                style={{
                    top:
                        -1 *
                            metricPositionMapper[
                                datum.devTags.symptom_checklist_concerns.toLowerCase()
                            ] +
                        7.5,
                }}
            >
                <IconStar />
            </div>
        );

        return (
            <td style={{ width: cellWidth }} key={i}>
                <div className={style.dateCell}>
                    {dataPoint}
                    {label}
                </div>
            </td>
        );
    }

    return (
        <div>
            <div className={style.legend}>
                <span>KEY</span>
                <span>
                    <span className={`${style.key} ${style.blueBg1}`} />
                    Never
                </span>
                <span>
                    <span className={`${style.key} ${style.blueBg2}`} />
                    Rarely
                </span>
                <span>
                    <span className={`${style.key} ${style.blueBg3}`} />
                    Sometimes
                </span>
                <span>
                    <span className={`${style.key} ${style.blueBg4}`} />
                    Often
                </span>
                <span>
                    <span className={`${style.key} ${style.blueBg5}`} />
                    Always
                </span>
            </div>
            <div className={style.chartWrapper}>
                <table style={{ width: tableWidth }}>
                    <tbody>
                        <tr>
                            <td className={style.rowTitle}>
                                <span>Always</span>
                            </td>
                            {renderRow(style.blueBg5)}
                        </tr>
                        <tr>
                            <td className={style.rowTitle}>
                                <span>Often</span>
                            </td>
                            {renderRow(style.blueBg4)}
                        </tr>
                        <tr>
                            <td className={style.rowTitle}>
                                <span>Sometimes</span>
                            </td>
                            {renderRow(style.blueBg3)}
                        </tr>
                        <tr>
                            <td className={style.rowTitle}>
                                <span>Rarely</span>
                            </td>
                            {renderRow(style.blueBg2)}
                        </tr>
                        <tr>
                            <td className={style.rowTitle}>
                                <span>Never</span>
                            </td>
                            {renderRow(style.blueBg1)}
                        </tr>
                        <tr className={style.xAxis}>{renderAxisRow(style.date)}</tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ConcernChart.propTypes = {
    data: PropTypes.array.isRequired,
    xAxisDates: PropTypes.array.isRequired,
    monthsInterval: PropTypes.number.isRequired,
};

export default ConcernChart;
