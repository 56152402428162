import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import IconDown from 'cccisd-icons/arrow-down20';
import IconRight from 'cccisd-icons/arrow-right20';
import IconEmail from 'cccisd-icons/envelop3';
import { Link } from 'cccisd-react-router';
import style from '../style.css';

const Appdefs = window.cccisd.appDefs;
const collapsedHeight = '58px';

function CoachInfo({ data }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const expandContainer = useRef(null);

    let defaultBio;
    try {
        defaultBio = Appdefs.pawn.fields.find(f => f.handle === 'bio').defaultForUi;
    } catch (e) {
        // meh, no biggie
    }

    function handleClickExpand() {
        let height = collapsedHeight;
        if (!isExpanded) {
            // content + 20px padding (10 top, 10 bottom)
            height = expandContainer.current.firstChild.offsetHeight + 20 + 'px';
        }
        expandContainer.current.style.maxHeight = height;
        setIsExpanded(prevState => !prevState);
    }

    function capitalizedName() {
        return `${data.user.firstName ? data.user.firstName[0].toUpperCase() + data.user.firstName.slice(1) : ''} ${
            data.user.lastName ? data.user.lastName[0].toUpperCase() + '.' : ''
        }`;
    }

    if (!data.user.username) {
        return null;
    }

    return (
        <div className={classnames(style.dashboardSection, style.bordered)}>
            <h3 className={style.title}>Coach</h3>
            <p>
                Your Coach is: <b>{capitalizedName()}</b>
            </p>
            {data.user.email && (
                <p className={style.showEmail}>
                    Email: <b>{data.user.email}</b>
                </p>
            )}
            <div className={style.expandable} ref={expandContainer} style={{ maxHeight: collapsedHeight }}>
                <div>
                    <div className={style.spaceBetween}>
                        <button
                            className={classnames('btn', 'btn-link', style.expandButton)}
                            type="button"
                            onClick={handleClickExpand}
                        >
                            {isExpanded ? <IconDown /> : <IconRight />}&nbsp;&nbsp;Meet My Coach
                        </button>
                        {data.user.email && (
                            <Link to="/messages">
                                <button type="button" className={classnames('btn', 'btn-primary', style.emailCoach)}>
                                    <IconEmail />
                                    &nbsp;&nbsp;Message My Coach
                                </button>
                            </Link>
                        )}
                    </div>
                    <div className={style.expandContent}>
                        <p>
                            <b>{capitalizedName()}</b>
                        </p>
                        <p>{data.fields.bio || defaultBio}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

CoachInfo.propTypes = {
    data: PropTypes.object,
};

export default CoachInfo;
