import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../../components/Footer';
import PrimaryNav from '../../components/NavBar';
import style from './style.css';

const MainLayout = ({ children, className = 'container', showSecondaryNav = false }) => {
    return (
        <div className={style.wrapper}>
            <div className={style.header}>
                <PrimaryNav className={className} showSecondaryNav={showSecondaryNav} />
            </div>
            <div className={style.body}>
                <div className={className}>{children}</div>
            </div>

            <div className={style.footer}>
                <Footer className={className} />
            </div>
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    showSecondaryNav: PropTypes.bool,
};

export default MainLayout;
