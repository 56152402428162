import React, { useState } from 'react';
import axios from 'cccisd-axios';
import IconUser from 'cccisd-icons/user-plus';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import { getUTCStringNow } from '../../../helpers';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const AcceptClient = ({ row, reloadTables }) => {
    const [isLoading, setIsLoading] = useState(false);

    async function handleClick() {
        setIsLoading(true);
        const response = await axios.put(
            Boilerplate.route('api.nexus.pawn.update', { pawn: row['pawn.pawnId'] }),
            { pawnHash: row['pawn.pawnHash'], coachAcceptedAt: getUTCStringNow() }
        );

        if (response.data.status === 'error') {
            notification({
                type: 'danger',
                message: `Could not accept client ${row['user.username']}`,
                data: response.data.errors,
                duration: 0,
            });
        } else {
            notification({
                type: 'success',
                message: `Client ${row['user.username']} was accepted!`,
            });
        }

        setIsLoading(false);
        reloadTables();
    }

    return (
        <Tooltip title="Accept Client">
            <button
                type="button"
                className={'btn btn-xs btn-success ' + style.btnSpacing}
                onClick={handleClick}
                disabled={isLoading}
            >
                <IconUser />
            </button>
        </Tooltip>
    );
};

export default AcceptClient;
