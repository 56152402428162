import React from 'react';
import axios from 'cccisd-axios';
import Confirm from 'cccisd-confirm';
import IconGraduation from 'cccisd-icons/graduation';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const Graduate = ({ row, reloadTables }) => {
    function getEnrolledText() {
        let text = row['pawn.createdDate'];
        if (row.enrolled === 1) {
            return text + ' (1 day)';
        }
        if (row.enrolled < 7) {
            return text + ` (${row.enrolled} days)`;
        }
        if (row.enrolled < 14) {
            return text + ` (1 week)`;
        }
        return text + ` (${Math.ceil(row.enrolled / 7)} weeks)`;
    }

    function handleClick() {
        Confirm({
            message: `Are you sure you want to graduate client ${row['user.username']}?`,
            description: (
                <div>
                    <p>This will remove them from your dashboard area.</p>
                    <table className={style.confirmTable}>
                        <tbody>
                            <tr>
                                <td>Username:</td>
                                <td>{row['user.username']}</td>
                            </tr>
                            <tr>
                                <td>Enrolled:</td>
                                <td>{getEnrolledText()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ),
            confirmLabel: 'Yes',
            abortLabel: 'Cancel',
        })
            .then(async () => {
                const response = await axios.put(Boilerplate.route('api.app.client.graduate'), {
                    clientPawnId: row['pawn.pawnId'],
                    clientPawnHash: row['pawn.pawnHash'],
                });
                if (response.data.status === 'error') {
                    notification({
                        message: 'Error graduating client:',
                        type: 'danger',
                        data: response.data.errors,
                        duration: 0,
                    });
                } else {
                    notification({
                        message: `Client ${row['user.username']} graduated successfully!`,
                        type: 'success',
                    });
                    reloadTables();
                }
            })
            .fail(() => {});
    }

    return (
        <Tooltip title="Graduate Client">
            <button
                type="button"
                className={`btn btn-xs btn-primary ${style.graduate} ${style.btnSpacing}`}
                onClick={handleClick}
            >
                <IconGraduation />
            </button>
        </Tooltip>
    );
};

export default Graduate;
