import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const NavBar = ({ className, noLogoLink }) => {
    let primaryNavLinks = [];
    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
    } catch (e) {
        // meh, leave it empty
    }

    if (Fortress.user.acting.role.handle === 'instructor' && !primaryNavLinks.some(nav => nav.label === 'Courses')) {
        const courseBase = {
            label: 'Courses',
            url: '#',
            children: [],
        };

        if (Fortress.user.acting.role_data.coachAdults) {
            courseBase.children.push({ url: '/survey/adult', label: 'Adult Course' });
        }

        if (Fortress.user.acting.role_data.coachTeens) {
            courseBase.children.push({ url: '/survey/teen', label: 'Teen Course' });
        }

        if (courseBase.children.length) {
            primaryNavLinks.unshift(courseBase);
        }
    }

    if (
        Fortress.user.acting.role.handle === 'learner' &&
        Fortress.user.acting.role_data.coachAcceptedAt &&
        !primaryNavLinks.some(nav => nav.label === 'Messaging')
    ) {
        primaryNavLinks.push({ label: 'Messaging', url: '/messages', highlight: ['/messages'] });
    }

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logoPlaceholder}
            primaryNavLinks={primaryNavLinks}
            showHomeAction={false}
            {...(noLogoLink ? { logoLink: null } : {})}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
