import React, { useEffect, useState } from 'react';
import { client } from 'cccisd-apollo';
import { SecureMessagingMultipleConversations } from 'cccisd-laravel-secure-messaging';
import query from './instructor.graphql';
import style from './style.css';

function SecureMessagingInstructor() {
    const [anotherParticipants, setAnotherParticipants] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchLearners() {
            const response = await client.query({
                query,
                variables: {},
                fetchPolicy: 'network-only',
            });

            const learnerList = response.data.roles.learnerList;
            const newAnotherParticipants = learnerList.map(learner => ({
                id: learner.pawn.pawnId,
                firstName: learner.user.firstName,
                lastName: learner.user.lastName,
                username: learner.user.username,
            }));

            setAnotherParticipants(newAnotherParticipants);
            setLoading(false);
        }

        fetchLearners();
    }, []);

    if (loading) {
        return null;
    }

    const user = window.cccisd.fortress.user;
    const primaryParticipant = {
        id: user.pawnId,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
    };

    return (
        <div className={style.messageBox}>
            <SecureMessagingMultipleConversations
                primaryParticipant={primaryParticipant}
                anotherParticipants={anotherParticipants}
            />
        </div>
    );
}

export default SecureMessagingInstructor;
