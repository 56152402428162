import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { client } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import IconCalendar from 'cccisd-icons/calendar-empty';
import Loader from 'cccisd-loader';
import CoachBioForm from './CoachBioForm';
import adult from '../../../images/A_Icon.svg';
import teenCoached from '../../../images/TC_Icon.svg';
import teenUncoached from '../../../images/TU_Icon.svg';
import selfHelpB from '../../../images/SW_Icon.svg';
import {
    CourseData,
    CourseProgress,
    Demographics,
    Graduate,
    Meals,
    AcceptClient,
    SymptomChecklist,
    Unlink,
    FlagClient,
    MessageClient,
} from './renderButtons';
import deploymentIdsQuery from './deploymentIds.graphql';
import checkPendingQuery from './checkPendingClients.graphql';
import pendingQuery from './pendingClients.graphql';
import clientsQuery from './clients.graphql';
import coachCheckPending from './coachCheckPendingClients.graphql';
import coachPendingClients from './coachPendingClients.graphql';
import style from './style.css';
import Tooltip from 'cccisd-tooltip';

const imageMapper = {
    adult,
    teenCoached,
    teenUncoached,
    selfHelpB,
};

const Appdefs = window.cccisd.appDefs;

const Dashboard = props => {
    const { role } = props;
    const [isPendingClients, setIsPendingClients] = useState(false);
    const [deploymentIdMapper, setDeploymentIdMapper] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const programFieldLabelMapper = {};
    (Appdefs.pawn.fields.find(f => f.handle === 'program')?.values || []).forEach(val => {
        programFieldLabelMapper[val.value] = val.name;
    });

    async function checkIsPendingClients() {
        if (role === 'instructor') {
            setIsLoading(true);
            const response = await client.query({
                query: coachCheckPending,
                fetchPolicy: 'network-only',
            });
            setIsPendingClients(response.data.roles.learnerCount > 0);
            setIsLoading(false);
        } else {
            setIsLoading(true);
            const response = await client.query({
                query: checkPendingQuery,
                fetchPolicy: 'network-only',
            });
            setIsPendingClients(response.data.roles.learnerCount > 0);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        async function getDeploymentIdMapper() {
            const response = await client.query({
                query: deploymentIdsQuery,
                fetchPolicy: 'network-only',
            });

            const map = {};
            response.data.flows.deploymentList.forEach(d => {
                map[d.deploymentHandle] = d.deploymentId;
            });
            setDeploymentIdMapper(map);
        }

        getDeploymentIdMapper();
        checkIsPendingClients();
    }, []);

    let loadPendingData = () => {};
    let loadAcceptedData = () => {};

    const reloadTables = () => {
        if (role !== 'instructor') {
            checkIsPendingClients();
        }
        loadPendingData();
        loadAcceptedData();
    };

    const commonColumns = [
        { name: 'user.firstName', label: 'First Name', sort: true, filter: true },
        { name: 'user.lastName', label: 'Last Name', sort: true, filter: true },
        {
            name: 'user.username',
            label: 'Username',
            sort: true,
            filter: true,
            render: ({ value }) => (
                <a href={'mailto:' + value} className={style.underline}>
                    {value}
                </a>
            ),
        },
        {
            name: 'fields.program',
            label: 'Program',
            sort: true,
            filter: true,
            filterSettings: {
                type: 'selectbox',
                options: Appdefs.pawn.fields
                    .find(f => f.handle === 'program')
                    .values.map(opt => ({ value: opt.value, label: opt.name }))
                    .filter(field => {
                        if (role === 'instructor' && ['teenUncoached', 'selfHelpB'].includes(field.value)) {
                            return false;
                        }

                        return true;
                    }),
            },
            render: 'program',
            className: style.Icon,
        },
    ];

    if (role === 'siteAdmin') {
        commonColumns.splice(3, 0, {
            name: 'parentRoles.instructor.user.username',
            label: 'Coach',
            sort: true,
            filter: true,
        });
    }

    const commonProps = {
        rowKey: 'pawn.pawnId',
        setRowClass: row => {
            if (row['fields.flagged']) {
                return style.flaggedRow;
            }
        },
        renders: {
            actions: renderProps => {
                return (
                    <>
                        <Graduate {...renderProps} reloadTables={reloadTables} />
                        {role === 'instructor' && <MessageClient {...renderProps} reloadTables={reloadTables} />}
                        {role === 'siteAdmin' &&
                        ['adult', 'teenCoached', 'teenUncoached'].includes(renderProps.row['fields.program']) ? (
                            <Unlink {...renderProps} reloadTables={reloadTables} />
                        ) : null}
                    </>
                );
            },
            pendingActions: renderProps => (
                <>
                    {role === 'instructor' && <AcceptClient {...renderProps} reloadTables={reloadTables} />}
                    {role === 'siteAdmin' && <Unlink {...renderProps} reloadTables={reloadTables} />}
                </>
            ),
            courseProgress: passThru => <CourseProgress {...passThru} deploymentIdMapper={deploymentIdMapper} />,
            data: renderProps => (
                <>
                    {['adult', 'teenCoached', 'teenUncoached'].includes(renderProps.row['fields.program']) && (
                        <SymptomChecklist {...renderProps} />
                    )}
                    <Meals {...renderProps} />
                    <CourseData {...renderProps} />
                    <Demographics {...renderProps} />
                    <FlagClient {...renderProps} />
                </>
            ),
            dateString: ({ value }) => {
                const date = new Date(value);
                return value && <span>{format(date, 'MM/dd/yyyy')}</span>;
            },
            daysAgoToWeeks: ({ value }) => {
                const weekNum = Math.ceil(parseInt(value, 10) / 7);
                return (
                    <>
                        <IconCalendar />
                        &nbsp;Week {weekNum}
                    </>
                );
            },
            program: ({ value }) => {
                return (
                    <Tooltip title={programFieldLabelMapper[value]}>
                        <img src={imageMapper[value]} alt={programFieldLabelMapper[value]} height={25} />
                    </Tooltip>
                );
            },
        },
    };

    return (
        <Loader loading={isLoading} removeChildren>
            <div className={style.page}>
                {role === 'instructor' && <CoachBioForm />}
                {role !== 'instructor'
                    ? isPendingClients && (
                          <>
                              <h2>Pending Clients</h2>
                              <Table
                                  {...commonProps}
                                  name="pending"
                                  query={pendingQuery}
                                  noRecordsMessage="No more pending clients"
                                  columns={[
                                      {
                                          name: 'pendingActions',
                                          label: 'Actions',
                                          render: 'pendingActions',
                                          className: style.actionsColumn,
                                      },
                                      ...commonColumns,
                                      {
                                          name: 'eventSummary.max',
                                          label: 'Date Linked',
                                          sort: true,
                                          filter: true,
                                          filterSettings: { type: 'date' },
                                          render: 'dateString',
                                      },
                                  ]}
                                  render={({ loadData, data, renderDefault }) => {
                                      loadPendingData = () => loadData();
                                      return renderDefault();
                                  }}
                              />
                          </>
                      )
                    : isPendingClients && (
                          <>
                              <h2>Pending Clients</h2>
                              <Table
                                  {...commonProps}
                                  name="pending"
                                  query={coachPendingClients}
                                  noRecordsMessage="No more pending clients"
                                  columns={[
                                      {
                                          name: 'pendingActions',
                                          label: 'Actions',
                                          render: 'pendingActions',
                                          className: style.actionsColumn,
                                      },
                                      ...commonColumns,
                                      {
                                          name: 'eventSummary.max',
                                          label: 'Date Linked',
                                          sort: true,
                                          filter: true,
                                          filterSettings: { type: 'date' },
                                          render: 'dateString',
                                      },
                                  ]}
                                  render={({ loadData, data, renderDefault }) => {
                                      loadPendingData = () => loadData();
                                      return renderDefault();
                                  }}
                              />
                          </>
                      )}
                <h2>Clients</h2>
                <Table
                    {...commonProps}
                    name="active"
                    query={clientsQuery}
                    graphqlVariables={{ ...deploymentIdMapper }}
                    noRecordsMessage="No clients are currently assigned"
                    columns={[
                        {
                            name: 'actions',
                            label: 'Actions',
                            render: 'actions',
                            className: style.actionsColumn,
                        },
                        ...commonColumns,
                        {
                            name: 'enrolled',
                            label: 'Enrolled',
                            sort: true,
                            render: 'daysAgoToWeeks',
                            className: style.enrolledColumn,
                        },
                        {
                            name: 'user.lastLoginAt',
                            label: 'Last Login',
                            sort: true,
                            filter: true,
                            filterSettings: { type: 'date' },
                            render: 'dateString',
                        },
                        {
                            name: 'surveyProgressStatus',
                            label: 'Course Progress',
                            render: 'courseProgress',
                        },
                        {
                            name: 'data',
                            label: 'Client Data',
                            render: 'data',
                            className: style.dataColumn,
                        },
                    ]}
                    render={({ loadData, renderDefault }) => {
                        loadAcceptedData = () => loadData();
                        return renderDefault();
                    }}
                />
            </div>
        </Loader>
    );
};

Dashboard.propTypes = {
    role: PropTypes.string.isRequired,
};

export default Dashboard;
