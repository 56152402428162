import React from 'react';
import { Field, CccisdInput, CccisdWysiwyg, CccisdCheckboxInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';
import Tooltip from 'cccisd-tooltip';

export const initialValues = {
    title: `<h5 style="font-size: 24px;">Symptom Checklist:
                <span style="font-weight: normal;">Report</span>
            </h5>`,
    dateLabel: 'Date of Report',
    dateFormat: 'MMMM dd, yyyy',
    coachLabel: 'Coach',
    clientLabel: 'Client',
    isFullNames: false,
};

const DemographicsTableAgeBuilder = props => {
    return (
        <div>
            <Field name="title" component={CccisdWysiwyg} label="Header Title" />
            <Field name="dateLabel" component={CccisdInput} label="Date Label" />
            <Field
                name="dateFormat"
                component={CccisdInput}
                label={
                    <Tooltip title="Use date-fns format string. Click to view documentation.">
                        <a
                            href="https://date-fns.org/v2.21.3/docs/format"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Date Format
                        </a>
                    </Tooltip>
                }
            />
            <Field name="coachLabel" component={CccisdInput} label="Coach Label" />
            <Field name="clientLabel" component={CccisdInput} label="Client Label" />
            <Field name="isFullNames" component={CccisdCheckboxInput} label="Use Full Names" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(DemographicsTableAgeBuilder);
