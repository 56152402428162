import React, { useState } from 'react';
import axios from 'cccisd-axios';
import IconFlag from 'cccisd-icons/flag3';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const FlagClient = ({ row, loadData }) => {
    const [isFlagged, setIsFlagged] = useState(row['fields.flagged']);
    const [isLoading, setIsLoading] = useState(false);

    async function toggleFlagged() {
        setIsLoading(true);

        // axios should toaster errors automatically
        const resp = await axios.put(Boilerplate.route('api.app.client.toggleFlagged'), {
            clientPawnId: row['pawn.pawnId'],
            clientPawnHash: row['pawn.pawnHash'],
        });

        if (resp?.data?.status !== 'success') {
            notification({
                message: 'Could not toggle Flag',
                data: resp?.data?.errors || null,
                duration: 0,
                type: 'danger',
            });
        }

        setIsFlagged(prev => !prev);
        setIsLoading(false);
        await loadData();
    }

    return (
        <Tooltip title={isFlagged ? 'Unflag Client' : 'Flag Client'}>
            <button
                type="button"
                className={`btn btn-xs ${isFlagged ? 'btn-danger' : 'btn-primary'} ${style.btnSpacing}`}
                onClick={toggleFlagged}
                disabled={isLoading}
            >
                <IconFlag />
            </button>
        </Tooltip>
    );
};

export default FlagClient;
