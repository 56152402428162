import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import widgets from '../../widgets';
import style from './style.css';
import border from './border.png';
import dummyData from './dummyData.json';
import query from './devTags.graphql';

// ICONS
import IconBrain from 'cccisd-icons/brain';
import IconBubble from 'cccisd-icons/bubble';
import IconBubbleUser from 'cccisd-icons/bubble-user';
import IconClipboard6 from 'cccisd-icons/clipboard6';
import IconFileCheck2 from 'cccisd-icons/file-check2';
import IconQuestion4 from 'cccisd-icons/question4';
import IconSpoonKnife from 'cccisd-icons/spoon-knife';
import IconTarget from 'cccisd-icons/target';
import IconFileStats2 from 'cccisd-icons/file-stats2';
import IconFileText3 from 'cccisd-icons/file-text3';
import IconTrophy3 from 'cccisd-icons/trophy3';
import IconThumbsUp2 from 'cccisd-icons/thumbs-up2';
import IconUserCheck from 'cccisd-icons/user-check';
import IconReading from 'cccisd-icons/reading';
import IconSad2 from 'cccisd-icons/sad2';
import IconFlag5 from 'cccisd-icons/flag5';
import IconDumbbell from 'cccisd-icons/dumbbell';
import IconHeart7 from 'cccisd-icons/heart7';
import IconHaircut from 'cccisd-icons/haircut';
import IconMobile from 'cccisd-icons/mobile';
import IconSearch from 'cccisd-icons/search';
import IconUser from 'cccisd-icons/user';
import IconBubbleDots from 'cccisd-icons/bubble-dots';
import IconCheckmarkCircle from 'cccisd-icons/checkmark-circle';
import IconUsers4 from 'cccisd-icons/users4';
import IconUserPlus from 'cccisd-icons/user-plus';
import IconBubbles2 from 'cccisd-icons/bubbles2';
const iconMapper = {
    introduction_1: IconTarget,

    theme1session1_1: IconSpoonKnife,
    theme1session1_2: IconFileCheck2,
    theme1session1_3: IconBubbleUser,
    theme1session1_4: IconBubble,

    theme1session2_1: IconQuestion4,
    theme1session2_2: IconClipboard6,
    theme1session2_3: IconBrain,
    theme1session2_4: IconFileStats2,
    theme1session2_5: IconBubbleUser,
    theme1session2_6: IconBubble,

    theme1session3_1: IconTrophy3,
    theme1session3_2: IconThumbsUp2,
    theme1session3_3: IconBubbleUser,
    theme1session3_4: IconBubble,

    theme2session1_1: IconClipboard6,
    theme2session1_2: IconBubbleUser,
    theme2session1_3: IconBubble,

    theme2session2_1: IconUserCheck,
    theme2session2_2: IconFileText3,
    theme2session2_3: IconBubbleUser,
    theme2session2_4: IconBubble,

    theme3session1_1: IconSad2,
    theme3session1_2: IconUserCheck,
    theme3session1_3: IconUserCheck,
    theme3session1_4: IconBubbleUser,
    theme3session1_5: IconBubble,

    theme3session2_1: IconBubbleUser,
    theme3session2_2: IconBubble,

    theme3session3_1: IconFlag5,
    theme3session3_2: IconReading,
    theme3session3_3: IconBubbleUser,
    theme3session3_4: IconBubble,

    theme4session1_1: IconBrain,
    theme4session1_2: IconBubbleUser,
    theme4session1_3: IconBubble,

    theme4session2_1: IconDumbbell,
    theme4session2_2: IconQuestion4,
    theme4session2_3: IconBubbleUser,
    theme4session2_4: IconBubble,

    theme4session3_1: IconBrain,
    theme4session3_2: IconHeart7,
    theme4session3_3: IconBubbleUser,
    theme4session3_4: IconBubble,

    theme5session1_1: IconHaircut,
    theme5session1_2: IconMobile,
    theme5session1_3: IconSearch,
    theme5session1_4: IconBubbleUser,
    theme5session1_5: IconBubble,

    theme5session2_1: IconBrain,
    theme5session2_2: IconBrain,
    theme5session2_3: IconBubbleUser,
    theme5session2_4: IconBubble,

    theme5session3_1: IconUser,
    theme5session3_2: IconBrain,
    theme5session3_3: IconBubbleUser,
    theme5session3_4: IconBubble,

    theme6session1_1: IconBrain,
    theme6session1_2: IconUserCheck,
    theme6session1_3: IconBubbleUser,
    theme6session1_4: IconBubble,

    theme6session2_1: IconUserCheck,
    theme6session2_2: IconBrain,
    theme6session2_3: IconBubbleUser,
    theme6session2_4: IconBubble,

    theme6session3_1: IconUserCheck,
    theme6session3_2: IconBubbleDots,
    theme6session3_3: IconBrain,
    theme6session3_4: IconBubbleUser,
    theme6session3_5: IconBubble,

    theme7session1_1: IconReading,
    theme7session1_2: IconUserCheck,
    theme7session1_3: IconBubbleUser,
    theme7session1_4: IconBubble,

    theme7session2_1: IconReading,
    theme7session2_2: IconUserCheck,
    theme7session2_3: IconUserCheck,
    theme7session2_4: IconBubbleUser,
    theme7session2_5: IconBubble,

    theme8session1_1: IconCheckmarkCircle,
    theme8session1_2: IconClipboard6,
    theme8session1_3: IconUserCheck,
    theme8session1_4: IconUsers4,
    theme8session1_5: IconBubbleUser,
    theme8session1_6: IconBubble,

    theme8session2_1: IconUserPlus,
    theme8session2_2: IconBubbles2,
    theme8session2_3: IconBubble,
};

import { initialValues } from '../Builder';

const CourseDataPlayer = props => {
    const {
        deploymentHandle,
        pawnId,
        settings: { headers, subheaders, questions },
        widgets: { header },
        isPreview,
        clientInfo,
    } = props;

    const [data, setData] = useState(null);
    useEffect(() => {
        async function getData() {
            const response = await client.query({
                query,
                variables: {
                    pawnId,
                    deploymentHandle,
                },
                fetchPolicy: 'network-only',
            });
            setData(response.data.roles.anyRole.assignmentProgress.devTags);
        }

        if (isPreview) {
            setData(dummyData);
        } else {
            getData();
        }
    }, []);

    function renderScaleAnswer(scale, devTag) {
        return (
            <div className={style.scaleAnswer}>
                {scale.map(val => (
                    <span className={data[devTag].toLowerCase() === val.toLowerCase() ? style.selected : ''} key={val}>
                        {val}
                    </span>
                ))}
            </div>
        );
    }

    function renderAnswer(devTag) {
        if (!data[devTag]) {
            return <p className={style.textAnswer}>&nbsp;</p>;
        }

        const importantScale = [
            'Not Important',
            'A Little Important',
            'Moderately Important',
            'Very Important',
            'Extremely Important',
        ];
        if (importantScale.some(val => val.toLowerCase() === data[devTag].toLowerCase())) {
            return renderScaleAnswer(importantScale, devTag);
        }

        const howReadyScale = ["I don't think I am ready for this", 'I am willing to try it', 'I already am'];
        if (howReadyScale.some(val => val.toLowerCase() === data[devTag].toLowerCase())) {
            return renderScaleAnswer(howReadyScale, devTag);
        }

        const helpfulScale = ['Not helpful at all', 'A little helpful', 'Very helpful', 'Extremely helpful'];
        if (helpfulScale.some(val => val.toLowerCase() === data[devTag].toLowerCase())) {
            return renderScaleAnswer(helpfulScale, devTag);
        }

        const closerGoalsScale = ['Not really there yet...', 'Yes'];
        if (closerGoalsScale.some(val => val.toLowerCase() === data[devTag].toLowerCase())) {
            return renderScaleAnswer(closerGoalsScale, devTag);
        }

        return <p className={style.textAnswer}>{data[devTag]}</p>;
    }

    function renderSection(name, questionCountPerSubheader) {
        const subheaderList = Object.keys(subheaders).filter(k => k.startsWith(name));

        return (
            <div className={style.section} key={name}>
                <div>
                    <Html content={headers && headers[name]} />
                </div>
                <img src={border} alt="border" className={style.border} />
                {subheaderList.map(subhead => {
                    if (!questionCountPerSubheader[subhead]) {
                        return null;
                    }

                    const Icon = iconMapper[subhead];
                    return (
                        <div key={subhead} className={style.subSection}>
                            <div className={style.subheader}>
                                {Icon && (
                                    <span className={style.icon}>
                                        <Icon />
                                    </span>
                                )}
                                <span className={style.subheaderText}>
                                    <h5>{subheaders && subheaders[subhead]}</h5>
                                </span>
                            </div>
                            {Array.from({ length: 10 }).map((_, i) => {
                                const devTag = `${subhead}_${i + 1}`;
                                return (
                                    questions[devTag] && (
                                        <div key={i}>
                                            <p className={style.question}>
                                                {i + 1}. {questions[devTag]}
                                            </p>
                                            <div className={style.answer}>{renderAnswer(devTag)}</div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    if (!data) {
        return (
            <div className={style.loader}>
                <Loader loading type="inline" />
            </div>
        );
    }

    const questionCountPerHeader = {};
    const questionCountPerSubheader = {};
    for (const [questionKey, questionText] of Object.entries(questions)) {
        const headerName = questionKey.slice(0, questionKey.indexOf('_'));
        const subheaderName = questionKey.slice(0, questionKey.lastIndexOf('_'));

        if (!questionCountPerHeader[headerName]) {
            questionCountPerHeader[headerName] = 0;
        }
        if (!questionCountPerSubheader[subheaderName]) {
            questionCountPerSubheader[subheaderName] = 0;
        }

        if (questionText) {
            questionCountPerHeader[headerName]++;
            questionCountPerSubheader[subheaderName]++;
        }
    }

    const now = new Date();
    return (
        <div>
            <Report
                downloadFilename={`Course Data Report_${isPreview ? 'Firstname' : clientInfo.firstName}_${
                    now.getMonth() + 1
                }-${now.getDate()}-${now.getFullYear()}`}
            >
                <Page>
                    {header}
                    {Object.keys(initialValues.headers).map(k =>
                        questionCountPerHeader[k] ? renderSection(k, questionCountPerSubheader) : null
                    )}
                </Page>
            </Report>
        </div>
    );
};

CourseDataPlayer.propTypes = {
    deploymentHandle: PropTypes.string.isRequired,
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    pawnId: PropTypes.number,
    clientInfo: PropTypes.object,
};

export default reportTemplatePlayer({
    getWidgets: props =>
        widgets.map(widget => ({
            ...widget,
            widgetProps: { clientInfo: props.clientInfo },
        })),
    filterFields: [],
})(CourseDataPlayer);
