import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isAfter, format, sub, parseISO, compareAsc } from 'date-fns';
import { client } from 'cccisd-apollo';
import IconCheckmark from 'cccisd-icons/checkmark4';
import IconDown from 'cccisd-icons/arrow-down20';
import IconRight from 'cccisd-icons/arrow-right20';
import IconLock from 'cccisd-icons/lock4';
import IconWarning from 'cccisd-icons/warning2';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import ExternalQuest from '../../../../ExternalQuest';
import MealReport from '../../../../MealReport';
import MealFullTable from '../../../../MealFullTable';
import SymptomChecklistReport from '../../../../SymptomChecklistReport';
import mealQuery from './mealProgress.graphql';
import { calculateDisabled } from '../../../../../helpers';
import style from '../../style.css';

const now = new Date();
const initialMealData = [
    {
        date: format(sub(now, { days: -1 }), 'yyyy-MM-dd'),
        planningComplete: false,
        trackingComplete: false,
    },
    { date: format(now, 'yyyy-MM-dd'), planningComplete: false, trackingComplete: false },
    {
        date: format(sub(now, { days: 1 }), 'yyyy-MM-dd'),
        planningComplete: false,
        trackingComplete: false,
    },
    {
        date: format(sub(now, { days: 2 }), 'yyyy-MM-dd'),
        planningComplete: false,
        trackingComplete: false,
    },
    {
        date: format(sub(now, { days: 3 }), 'yyyy-MM-dd'),
        planningComplete: false,
        trackingComplete: false,
    },
    {
        date: format(sub(now, { days: 4 }), 'yyyy-MM-dd'),
        planningComplete: false,
        trackingComplete: false,
    },
    {
        date: format(sub(now, { days: 5 }), 'yyyy-MM-dd'),
        planningComplete: false,
        trackingComplete: false,
    },
];

const collapsedHeight = '58px';
const Fortress = window.cccisd.fortress;

function OtherSurveyInfo({ isPreviewMode, symptomSurveyData }) {
    const [isMealExpanded, setIsMealExpanded] = useState(false);
    const [mealData, setMealData] = useState(initialMealData);
    const [isMealLoading, setIsMealLoading] = useState(true);
    const [isOlderDatesButton, setIsOlderDatesButton] = useState(false);
    const expandMealContainer = useRef(null);

    const [isSymptomExpanded, setIsSymptomExpanded] = useState(false);
    const [disableSymptomSurvey, setDisableSymptomSurvey] = useState(true);
    const expandSymptomContainer = useRef(null);

    async function getMealProgress() {
        setIsMealLoading(true);
        if (isPreviewMode) {
            setMealData([
                {
                    date: format(sub(now, { days: -1 }), 'yyyy-MM-dd'),
                    planningComplete: false,
                    trackingComplete: false,
                },
                {
                    date: format(now, 'yyyy-MM-dd'),
                    planningComplete: false,
                    trackingComplete: false,
                },
                {
                    date: format(sub(now, { days: 1 }), 'yyyy-MM-dd'),
                    planningComplete: true,
                    trackingComplete: false,
                },
                {
                    date: format(sub(now, { days: 2 }), 'yyyy-MM-dd'),
                    planningComplete: true,
                    trackingComplete: true,
                },
            ]);
            setIsMealLoading(false);
            return;
        }
        const resp = await client.query({
            query: mealQuery,
            variables: {
                pawnId: Fortress.user.acting.id,
            },
            fetchPolicy: 'network-only',
        });

        const activatedDate = resp.data.roles.learner.user.activatedDate;
        if (activatedDate && compareAsc(parseISO(activatedDate), sub(new Date(), { days: 7 })) === -1) {
            setIsOlderDatesButton(true);
        } else {
            setIsOlderDatesButton(false);
        }

        const meals = [];
        [-1, 0, 1, 2, 3, 4, 5].forEach(num => {
            const date = format(sub(now, { days: num }), 'yyyy-MM-dd');
            if (activatedDate && compareAsc(parseISO(date), parseISO(activatedDate)) >= 1) {
                const planningDeployments = resp.data.flows.planning.filter(
                    depl => depl.devTags.meal_planning_date === date
                );
                const planning = planningDeployments.reduce(
                    (prev, current) =>
                        prev && isAfter(new Date(prev.completedAt), new Date(current.completedAt)) ? prev : current,
                    null
                );

                const trackingDeployments = resp.data.flows.tracking.filter(
                    depl => depl.devTags.meal_tracking_date === date
                );
                const tracking = trackingDeployments.reduce(
                    (prev, current) =>
                        prev && isAfter(new Date(prev.completedAt), new Date(current.completedAt)) ? prev : current,
                    null
                );

                meals.push({
                    date,
                    planningComplete: !!planning,
                    planningDeploymentId: planning && planning.deployment.deploymentId,
                    trackingComplete: !!tracking,
                    trackingDeploymentId: tracking && tracking.deployment.deploymentId,
                });
            }
        });
        setMealData(meals);
        setIsMealLoading(false);
    }

    useEffect(() => {
        getMealProgress();
    }, []);

    useEffect(() => {
        setDisableSymptomSurvey(
            calculateDisabled(
                symptomSurveyData.data.progress.map(depl => ({
                    deploymentId: depl.deployment.deploymentId,
                    completedAt: depl.completedAt,
                }))
            )
        );
    }, [symptomSurveyData]);

    function handleClickExpand(name) {
        const isExpanded = name === 'meal' ? isMealExpanded : isSymptomExpanded;
        const node = name === 'meal' ? expandMealContainer.current : expandSymptomContainer.current;
        const setExpanded = name === 'meal' ? setIsMealExpanded : setIsSymptomExpanded;
        const height = isExpanded ? collapsedHeight : node.firstChild.offsetHeight + 20 + 'px';

        node.style.maxHeight = height;
        setExpanded(prevState => !prevState);
    }

    const MealSurveyModal = ({ handle, date, triggerDisabled }) => {
        const beginButton = (
            <button
                type="button"
                className={classnames('btn', style.beginButton)}
                disabled={triggerDisabled}
                onClick={() => {
                    if (isPreviewMode) {
                        notification({ type: 'info', message: 'Not available in preview mode' });
                    }
                }}
            >
                {triggerDisabled && (
                    <>
                        <IconLock />
                        &nbsp;
                    </>
                )}
                Begin
            </button>
        );

        return (
            <Modal
                title={handle === 'mealPlanning' ? 'Meal Planning' : 'Meal Tracking'}
                size="large"
                trigger={triggerDisabled ? <Tooltip title="Opens tomorrow">{beginButton}</Tooltip> : beginButton}
                triggerDisabled={isPreviewMode || triggerDisabled}
                render={({ closeModal }) => (
                    <ExternalQuest
                        handle={handle}
                        isPreviewMode={isPreviewMode}
                        onBack={() => closeModal()}
                        onComplete={getMealProgress}
                        defaultDate={date}
                        triggerDisabled={triggerDisabled}
                    />
                )}
            />
        );
    };
    return (
        <div className={classnames(style.dashboardSection, style.bordered)}>
            <h3 className={style.title}>everyBody Tools</h3>
            {Fortress.user.acting.role_data.program !== 'selfHelpB' && (
                <div
                    ref={expandSymptomContainer}
                    className={classnames(style.expandable, style.firstRow)}
                    style={{ maxHeight: collapsedHeight }}
                >
                    <div>
                        <div className={style.spaceBetween}>
                            <button
                                className={classnames('btn', 'btn-link', style.expandButton)}
                                type="button"
                                onClick={() => handleClickExpand('symptom')}
                            >
                                {isSymptomExpanded ? <IconDown /> : <IconRight />}
                                &nbsp;&nbsp;Symptom Checklist
                            </button>
                            <span>
                                <Modal
                                    title="Symptom Checklist"
                                    size="large"
                                    triggerDisabled={isPreviewMode || disableSymptomSurvey}
                                    trigger={
                                        <Tooltip title="Survey opens Sundays 1am CT">
                                            <button
                                                disabled={disableSymptomSurvey}
                                                type="button"
                                                className={classnames('btn', style.beginButton)}
                                                onClick={() => {
                                                    if (isPreviewMode) {
                                                        notification({
                                                            type: 'info',
                                                            message: 'Not available in preview mode',
                                                        });
                                                    }
                                                }}
                                            >
                                                {disableSymptomSurvey && (
                                                    <>
                                                        <IconLock />
                                                        &nbsp;
                                                    </>
                                                )}
                                                Begin
                                            </button>
                                        </Tooltip>
                                    }
                                    render={({ closeModal }) => (
                                        <ExternalQuest
                                            handle="symptomChecklist"
                                            isPreviewMode={isPreviewMode}
                                            onComplete={symptomSurveyData.get}
                                            onBack={() => closeModal()}
                                        />
                                    )}
                                />
                                {disableSymptomSurvey && symptomSurveyData.data !== symptomSurveyData.initial && (
                                    <span className={'text-success ' + style.iconBig}>
                                        <IconCheckmark />
                                    </span>
                                )}
                            </span>
                        </div>
                        <div className={style.reportContainer}>
                            <SymptomChecklistReport
                                clientPawnId={Fortress.user.acting.id}
                                noModal
                                symptomSurveyData={symptomSurveyData.data}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div ref={expandMealContainer} className={style.expandable} style={{ maxHeight: collapsedHeight }}>
                <div>
                    <div className={style.spaceBetween}>
                        <button
                            className={classnames('btn', 'btn-link', style.expandButton)}
                            type="button"
                            onClick={() => handleClickExpand('meal')}
                        >
                            {isMealExpanded ? <IconDown /> : <IconRight />}&nbsp;&nbsp;Meals
                        </button>
                        {(() => {
                            if (mealData === initialMealData) return null;
                            return mealData &&
                                mealData.some(
                                    entry =>
                                        !entry.planningComplete ||
                                        (!entry.trackingComplete && compareAsc(parseISO(entry.date), new Date()) === -1)
                                ) ? (
                                <span className={`${style.warnColor} ${style.iconBig}`}>
                                    <IconWarning />
                                </span>
                            ) : (
                                <span className={'text-success ' + style.iconBig}>
                                    <IconCheckmark />
                                </span>
                            );
                        })()}
                    </div>
                    {mealData.length > 0 ? (
                        <div className={style.mealTable}>
                            {isMealLoading && (
                                <div
                                    className={style.spinnerContainer}
                                    style={{
                                        height: isOlderDatesButton ? 'calc(100% - 30px)' : '100%',
                                    }}
                                >
                                    <Loader loading type="inline" />
                                </div>
                            )}
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Meal Planning</th>
                                        <th>Meal Tracking</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mealData.map(row => {
                                        const isLastTwoDays = isAfter(parseISO(row.date), sub(new Date(), { days: 2 }));

                                        const disableTracking = isAfter(parseISO(row.date), new Date());

                                        return (
                                            <tr key={row.date}>
                                                <td>{format(parseISO(row.date), 'E, MMM do')}</td>
                                                <td>
                                                    {row.planningComplete ? (
                                                        <MealReport
                                                            handle="mealPlanning"
                                                            date={row.date}
                                                            deploymentId={row.planningDeploymentId}
                                                            isPreviewMode={isPreviewMode}
                                                        />
                                                    ) : (
                                                        <span>
                                                            <MealSurveyModal handle="mealPlanning" date={row.date} />
                                                            {isLastTwoDays && (
                                                                <span
                                                                    className={`${style.warnColor} ${style.warningIcon}`}
                                                                >
                                                                    <IconWarning />
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                </td>
                                                <td>
                                                    {row.trackingComplete ? (
                                                        <MealReport
                                                            handle="mealTracking"
                                                            date={row.date}
                                                            deploymentId={row.trackingDeploymentId}
                                                            isPreviewMode={isPreviewMode}
                                                        />
                                                    ) : (
                                                        <span>
                                                            <MealSurveyModal
                                                                handle="mealTracking"
                                                                date={row.date}
                                                                triggerDisabled={disableTracking}
                                                            />
                                                            {isLastTwoDays && !disableTracking && (
                                                                <span
                                                                    className={`${style.warnColor} ${style.warningIcon}`}
                                                                >
                                                                    <IconWarning />
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {isOlderDatesButton && (
                                <MealFullTable
                                    trigger={
                                        <button className={'btn btn-xs btn-link ' + style.olderDatesBtn} type="button">
                                            Show older dates
                                        </button>
                                    }
                                    isClient
                                    onSurveyComplete={getMealProgress}
                                    isPreviewMode={isPreviewMode}
                                />
                            )}
                        </div>
                    ) : (
                        <p className={'text-muted ' + style.noMealSurveyMsg}>
                            Client can begin the Meal Planning/Tracking surveys after their account has been activated.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

OtherSurveyInfo.propTypes = {
    isPreviewMode: PropTypes.bool,
    symptomSurveyData: PropTypes.object,
};

export default OtherSurveyInfo;
