import React from 'react';
import { Redirect } from 'cccisd-react-router';

const Fortress = window.cccisd && window.cccisd.fortress;

const urlMap = {
    adult: '/survey/adult',
    teenCoached: '/survey/teen',
    teenUncoached: '/survey/teen',
    selfHelpB: '/survey/staywell',
};

const learnerHomepage = () => {
    const program = Fortress.user.acting.role_data.program;
    return <Redirect to={urlMap[program]} />;
};

export default learnerHomepage;
